import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { Reflector } from "three/addons/objects/Reflector.js";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
import { checkScreenSize } from "./initial.js";

// TODO! Remove this before going live
// import Stats from "three/addons/libs/stats.module.js";

/**
 * @description This will load the 3D scene along with all required assets. Once it has loaded, it will then start the animation loop.
 * @param {domElement} initialDialog - The initial dialog box that is shown before the 3d scene loads
 * @param {domElement} initialDialogText - The text that is shown in the initial dialog box before the 3d scene loads
 * @param {domElement} initialScreenWrapper - The wrapper that holds the progress bar and text
 * @param {domElement} circleClip - The circle clip element (the black SVG overlay with the circle inside)
 * @param {domElement} progressBar - The progress bar element
 */
export const loadScene = (initialDialog, initialDialogText, initialScreenWrapper, circleClip, progressBar) => {
    // ================================================================================================================================
    /**
     * G L O B A L  V A R I A B L E S
     */
    // ================================================================================================================================

    // Reference the canvas element which will hold our scene
    const canvas = document.getElementById("webgl");

    // TODO! Remove this before going live
    // const stats = new Stats();
    // document.body.appendChild(stats.dom);

    let camera;
    let controls;

    // In order to save on performance, we won't constantly render the scene. Instead, we'll only render it when mouse moves.
    // However, we need to paint the scene at least once so that the user can see it before moving the mouse.
    let loadedFirstFrame = false;

    // We don't want to render out the scene when using the computer or the video game to save on performance
    let canRender = true;

    // Set to true when tweening the camera to focus on an object
    let isFocusingOnObject;

    // Can we raycast to find objects?
    let canRaycast = false;

    // Is the menu open? If the menu is open, we don't want to be able to interact with anything else
    let menuOpen = false;

    // Reference objects that will be loaded from the main scene GLTF file
    let selectedObject,
        clockRim,
        clockGlass,
        clockFaceObj,
        computerObj,
        computerScreenObj,
        corkboardObj,
        robotObj,
        tvObj,
        hiddenTVScreenObj,
        synthObj,
        guitarCase,
        recordPlayer,
        recordPlayerCoverObj,
        cassetteCoverObj,
        sodaCans,
        papersObj1,
        papersObj2,
        blanketObj,
        blanketObjMade,
        trash,
        centerObj,
        computerScreen,
        tvScreen,
        bedFrame,
        carpetCeiling,
        mattress;

    // Will reference the animated robot object
    let robotAnimated;

    // All the animations for the robot
    let robotAnimations;

    // Reference that will hold the robot animation mixer
    let robotAnimMixer;

    // Is the robot in view mode?
    let robotInViewMode = false;

    // Will reference the interactive synthesizer object
    let interactiveSynth;

    // Is the synthesizer in view mode?
    let synthInViewMode = false;

    // Animations for the record player
    let recordPlayerAnimations;

    // Reference that will hold the record player animation mixer
    let recordPlayerAnimMixer;

    // Is the record player in view mode?
    let recordPlayerInViewMode = false;

    // Has the record player animation been started yet?
    let recordPlayerAnimStarted = false;

    // Synthesizer keys
    let keyF0,
        keyFS0,
        keyG0,
        keyGS0,
        keyA0,
        keyAS0,
        keyB0,
        keyC,
        keyCS,
        keyD,
        keyDS,
        keyE,
        keyF,
        keyFS,
        keyG,
        keyGS,
        keyA,
        keyAS,
        keyB,
        keyC2,
        keyCS2,
        keyD2,
        keyDS2,
        keyE2,
        keyF2,
        keyFS2,
        keyG2,
        keyGS2,
        keyA2,
        keyAS2,
        keyB2;

    // Is the cork board in view mode?
    let corkboardInViewMode = false;

    // Reference the robot overlay dialog
    const robotOverlay = document.querySelector(".robot_overlay");

    // Reference the trash can overlay dialog
    const trashOverlay = document.querySelector(".trash_overlay");

    // Is the trash can in view mode?
    let trashInViewMode = false;

    // Is the bed/blanket in view mode?
    let blanketInViewMode = false;

    // Blanket overlay
    const blanketOverlay = document.querySelector(".blanket_overlay");

    // Has the bed been made?
    let bedMade = false;

    // Create variables to hold the clock hands
    let clockHour, clockMinute, clockSecond;

    // Is the clock in view mode?
    let clockInViewMode = false;

    // Used to store the last posotion of the camera before focusing on something
    let lastCameraPosition = null;

    // Used to store the current mesht that the raycaster is intersecting with
    let intersected;

    // Can we hover and focus over objects?
    let canHover = true;

    // Callback for when the camera stops focusing on the computer
    let resetCameraCallback = null;

    // Has the computer been started up yet?
    let computerStarted = false;

    // Has the game started yet?
    let gameStarted = false;

    // Is the game paused?
    let gamePaused = false;

    // Has the success overlay been shown yet?
    let successOverlayShown = false;

    // Is it raining down instrument images?
    let isRainingInstruments = false;

    // Texture loader
    const textureLoader = new THREE.TextureLoader();

    // Number of items discovered
    let discoverables = {
        clock: false,
        robot: false,
        synth: false,
        trash: false,
        computer: false,
        record: false,
        guitar: false,
        tv: false,
        soda: false,
        bed: false,
        corkboard: false
    };

    // Clock timer reference used for animations (robot)
    const clockTimer = new THREE.Clock();

    // ================================================================================================================================
    /**
     * P R E L O A D  A S S E T S
     */
    // ================================================================================================================================

    // Bed replacement images. Preload them so that they're ready to go when needed and don't cause a screen flash
    const bedFrameTexture = new THREE.TextureLoader().load("../assets/images/bedFrameNeat.jpg");
    bedFrameTexture.flipY = false;

    const carpetCeilingTexture = new THREE.TextureLoader().load("../assets/images/carpetNeat.jpg");
    carpetCeilingTexture.flipY = false;

    const mattressTexture = new THREE.TextureLoader().load("../assets/images/pillowBedBakeNeat.jpg");
    mattressTexture.flipY = false;

    // Preload the guitar case interaction images
    const synthImage = new Image();
    synthImage.src = "../assets/images/synth.svg";

    const guitarImage = new Image();
    guitarImage.src = "../assets/images/resonator.svg";

    const mandolinImage = new Image();
    mandolinImage.src = "../assets/images/mandolin.svg";

    const celloImage = new Image();
    celloImage.src = "../assets/images/cello.svg";

    const bassImage = new Image();
    bassImage.src = "../assets/images/bass.svg";

    const guitarMetalImage = new Image();
    guitarMetalImage.src = "../assets/images/resonatorMetal.svg";

    const mandolinLightImage = new Image();
    mandolinLightImage.src = "../assets/images/mandolinLight.svg";

    const celloWhiteImage = new Image();
    celloWhiteImage.src = "../assets/images/celloWhite.svg";

    const bassRedImage = new Image();
    bassRedImage.src = "../assets/images/bassRed.svg";

    const celloCarbon = new Image();
    celloCarbon.src = "../assets/images/celloCarbon.svg";

    const bassGreen = new Image();
    bassGreen.src = "../assets/images/bassGreen.svg";

    const guitarClassical = new Image();
    guitarClassical.src = "../assets/images/guitarClassical.svg";

    const saxophone = new Image();
    saxophone.src = "../assets/images/saxophone.svg";

    const electricGuitarGold = new Image();
    electricGuitarGold.src = "../assets/images/electricGuitarGold.svg";

    const electricGuitarRed = new Image();
    electricGuitarRed.src = "../assets/images/electricGuitarRed.svg";

    // Preload the confetti images
    const confetti1 = new Image();
    confetti1.src = "../assets/images/confetti1.svg";

    const confetti2 = new Image();
    confetti2.src = "../assets/images/confetti2.svg";

    const confetti3 = new Image();
    confetti3.src = "../assets/images/confetti3.svg";

    const confetti4 = new Image();
    confetti4.src = "../assets/images/confetti4.svg";

    const confetti5 = new Image();
    confetti5.src = "../assets/images/confetti5.svg";

    const confetti6 = new Image();
    confetti6.src = "../assets/images/confetti6.svg";

    const confetti7 = new Image();
    confetti7.src = "../assets/images/confetti7.svg";

    // Preload clock face images
    const clockFaceImage1 = new THREE.TextureLoader().load("../assets/images/clockFace1.jpg");
    clockFaceImage1.flipY = false;

    const clockFaceImage2 = new THREE.TextureLoader().load("../assets/images/clockFace2.jpg");
    clockFaceImage2.flipY = false;

    const clockFaceImage3 = new THREE.TextureLoader().load("../assets/images/clockFace3.jpg");
    clockFaceImage3.flipY = false;

    // Preload the record player song
    const recordPlayerSong = new Audio("../assets/audio/recordSong.mp3");

    // ================================================================================================================================
    /**
     * D O M  E L E M E N T  R E F E R E N C E S
     */
    // ================================================================================================================================
    // Reference the DOM element that holds the back button as well as the button
    const uiWrapper = document.querySelector(".ui_wrapper");

    // Go back button
    const goBackBtn = document.querySelector(".go_back_wrapper > button");

    // Soda cans info overlay
    const sodaCansInfo = document.querySelector(".soda_overlay");

    // Guiatar info overlay
    const guitarInfo = document.querySelector(".guitar_overlay");

    // Star icon
    const starIcon = document.querySelector(".star_btn");

    // Star icon tooltip
    const starTooltip = document.querySelector(".star_tooltip");

    // Star icon tooltip text
    const starTooltipText = document.querySelector(".star_tooltip > h2");

    // Intro wrapper close icon refrerence
    const introWrapperClose = document.querySelector(".intro_wrapper > .close_x");

    // Success wrapper (when all items have been discovered)
    const successOverlay = document.querySelector(".success_overlay");

    // Success wrapper close icon reference
    const successOverlayClose = document.querySelector(".success_overlay > .close_x");

    // Resume button reference
    let resumeBtn = document.querySelector(".corkboard_overlay > .inner_wrapper > button");

    // Cork board overlay reference
    const corkboardOverlay = document.querySelector(".corkboard_overlay");

    // Cork board overlay inner wrapper reference
    const corkboardOverlayInner = document.querySelector(".corkboard_overlay > .inner_wrapper");

    // Cork board overlay close button reference
    const corkboardOverlayClose = document.querySelector(".corkboard_overlay > .close_x");

    // Clock face overlay reference
    const clockFacesWrapper = document.querySelector(".clock_faces_select_wrapper");

    // Clock face choice #1
    const clockFace1 = document.querySelector(".clock_faces_select_wrapper > .clock_face_1");

    // Clock face choice #2
    const clockFace2 = document.querySelector(".clock_faces_select_wrapper > .clock_face_2");

    // Clock face choice #3
    const clockFace3 = document.querySelector(".clock_faces_select_wrapper > .clock_face_3");

    // Info button
    const infoBtn = document.querySelector(".info_btn_wrapper");

    // Info button overlay
    const infoOverlay = document.querySelector(".info_btn_wrapper > .info_overlay");

    // Info overlay inner text wrapper
    const infoOverlayTxt = document.querySelector(".info_btn_wrapper > .info_overlay > .info_text_wrapper");

    // ================================================================================================================================
    /**
     * C O N T R O L S  U I  R E F E R E N C E S
     */
    // ================================================================================================================================
    // Should the controls UI be visible?
    let controlsVisible = true;

    // Reference the controls wrapper
    const controlsWrapper = document.querySelector(".controls_wrapper_invisible_mask");

    // Controls UI overlay elements (controller buttons)
    const controllerDirBtn = document.querySelector(".controller_dir_btn");
    const controllerStartBtn = document.querySelector(".controller_start_btn");
    const controllerABtn = document.querySelector(".controller_a_btn");
    const controllerBBtn = document.querySelector(".controller_b_btn");

    // Reference the paragraph tag that will show the control key action
    const controlKeyAction = document.querySelector(".key_action");

    // Controls UI overlay elements (keyboard buttons)
    const controlsWrapperClose = document.querySelector(".controls_wrapper > .close_x");
    const keyboardShiftBtnEls = document.querySelectorAll(".keyboard_shift_btn");
    const keyboardSpaceBtn = document.querySelector(".keyboard_space_btn");
    const keyboardEnterBtnEls = document.querySelectorAll(".keyboard_enter_btn");
    const keyboardDirBtnEls = document.querySelectorAll(".keyboard_dir_btn");

    // Keyboard key "backlights"
    const keyboardEnterBacklight = document.querySelector(".keyboard_enter_backlight");
    const keyboardDirBacklight = document.querySelector(".keyboard_dir_backlight");
    const keyboardShiftBacklight = document.querySelector(".keyboard_shift_backlight");


    // ================================================================================================================================
    /**
     * S C E N E   S E T U P
     */
    // ================================================================================================================================

    // Instantiate a new scene
    const scene = new THREE.Scene();

    // Add the camera and set the initial position
    camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 10);

    // Instantiate the renderer
    const renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: true,
        precision: "highp",
        powerPreference: "high-performance"
    });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.35;


    // ================================================================================================================================
    /**
     * H D R   B A C K G R O U N D
     * (for reflections)
     */
    // ================================================================================================================================

    const hdrTextureURL = "../assets/3d/room.hdr";

    const loader = new RGBELoader();
    loader.load(hdrTextureURL, (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.background = texture;
        scene.environment = texture;
    });


    // ================================================================================================================================
    /**
 * R A Y C A S T E R
 */
    // ================================================================================================================================

    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    const onMouseDown = (event) => {
        if (!canHover || !canRaycast) return;

        // If the mouse click was within the bottom 45 pixels of the screen (where the navigation is) 
        // AND we are in focus mode (the ui wrapper will have the show class) don't do anything
        if (event.clientY > window.innerHeight - 45 && uiWrapper.classList.contains("show")) {
            return;
        }

        let vector = new THREE.Vector3((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1, 0.5);
        vector = vector.unproject(camera);

        const raycaster = new THREE.Raycaster(camera.position, vector.sub(camera.position).normalize());

        const intersects = raycaster.intersectObjects(scene.children, true);


        if (intersects.length > 0) {
        // If we're in view mode of the synthesizer, check for keyboard key presses
            if (synthInViewMode) {
                const synthKeys = [ keyF0, keyFS0, keyG0, keyGS0, keyA0, keyAS0, keyB0, keyC, keyCS, keyD, keyDS, keyE, keyF, keyFS, keyG, keyGS, keyA, keyAS, keyB, keyC2, keyCS2, keyD2, keyDS2, keyE2, keyF2, keyFS2, keyG2, keyGS2, keyA2, keyAS2, keyB2 ];

                // Check to see if any of the intersects are one of the syth keys
                for (let i = 0; i < 4; i++) {
                    if (synthKeys.includes(intersects[i].object)) {
                    // If so, play the synth sound
                        playSynthSound(intersects[i].object);
                        break;
                    }
                }
                return;
            }

            if (intersects[0].object === clockRim || intersects[0].object === clockGlass) {
                focusClock();
            } else if (intersects[0].object === robotObj) {
            // If we're not already viewing the robot, zoom in on it
                if (!robotInViewMode) {
                    focusRobot();
                } else {
                // Otherwise, when clicking on the robot, start the robot animation
                    robotInteraction();
                }
            } else if (intersects[0].object.name === "corkBoard") {
            // If we're not already viewing the cork board, zoom in on them
                if (!corkboardInViewMode) {
                    focusCorkboard();
                }
            } else if (intersects[0].object.name === "clipboardPapers") { // The paper on the left
            // If in view mode, handle showing the resume in the overlay
                if (corkboardInViewMode) {
                    showResume();
                } else {
                    focusCorkboard();
                }
            } else if (intersects[0].object.name === "clipboardPapers001") { // The paper on the right
            // If in view mode, handle showing the about text in the overlay
                if (corkboardInViewMode) {
                    showAbout();
                } else {
                    focusCorkboard();
                }
            } else if (intersects[0].object.name === "blanketMessy") {
            // If we're not already viewing the blanket, zoom in on it
                if (!blanketInViewMode) {
                    focusBlanket();
                } else {
                // If the bed hasn't been made yet, make it. Otherwise don't do anything when clicking on the blanket
                    if (!bedMade) {
                    // Otherwise, when clicking on the blanket, replace it with the made bed
                        makeTheBed();
                    }
                }
            } else if (intersects[0].object.name === "tvScreensaver") {
                focusTV();
            } else {
                const parentName = intersects[0].object.parent.name;

                const screensaver = scene.getObjectByName("screensaver");

                // If the (computer's) screensaver exists and it is the object that was clicked, focus on the computer
                // Or we clicked on the computer's floppy disk
                if (screensaver && intersects[0].object === screensaver || intersects[0].object.name === "Cube031") {
                    focusComputer();
                }

                switch (parentName) {
                    case "Computer":
                    case "screensaver":
                        focusComputer();
                        break;
                    case "synth":
                        if (!synthInViewMode) {
                            focusSynth();
                        }
                        break;
                    case "tv":
                        focusTV();
                        break;
                    case "guitarCase":
                        focusGuitarCase();
                        break;
                    case "recordPlayer":
                        focusRecordPlayer();
                        break;
                    case "sodaCans":
                        focusSodaCans();
                        break;
                    case "trash":
                    // If we're not alrady viewing the trash can, zoom in on it
                        if (!trashInViewMode) {
                            focusTrashCan();
                        } else {
                        // Otherwise, when clicking on the trash can, open the old portfolio
                            trashCanInteraction();
                        }
                        break;
                    default:
                    // Do nothing
                }

                // Apply the click event to the cassette cover located on top of the record player
                if (intersects[0].object.name === "cassetteCover" || intersects[0].object.name === "recordPlayerCover") {
                    focusRecordPlayer();
                }
            }
        }
    };

    // Called when the mouse pointer moves. Used for raycasting.
    const onPointerMove = (e) => {
        canRender = true;
        pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    // Called when the mouse scroll wheel is used. Used for zooming in and out.
    const onMouseWheel = () => {
        canRender = true;
    };

    // ================================================================================================================================
    /**
     * O R B I T A L  C O N T R O L S   S E T U P
     * (Navigation controls)
     */
    // ================================================================================================================================

    // Sets controls control to move the camera around
    controls = new OrbitControls(camera, renderer.domElement);

    camera.position.set(0, 0, 0.1);

    // Listen for key events
    controls.listenToKeyEvents(window);

    controls.update();

    // Disabled right clicking orbital controls (just make it the same as left click)
    controls.mouseButtons = {
        LEFT: THREE.MOUSE.LEFT,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.LEFT
    };

    // Minimum distance the camera can zoom into the scene
    controls.minDistance = 0.1;

    // Maximum distance the camera can be from the center of the scene
    controls.maxDistance = 2.5;

    // ================================================================================================================================
    /**
 * L I G H T S   S E T U P
 */
    // ================================================================================================================================

    // Ambient light
    const ambientLight = new THREE.AmbientLight(0x333333);
    ambientLight.intensity = 80;
    ambientLight.position.y = 2;
    scene.add(ambientLight);

    // Desk lamp light
    const deskLampPointLight = new THREE.PointLight(0xFFF9E5, 8, 2);
    deskLampPointLight.position.x = 1.651;
    deskLampPointLight.position.y = 0.43;
    deskLampPointLight.position.z = -1.596;
    scene.add(deskLampPointLight);

    // Overhead central light
    const overheadLight = new THREE.DirectionalLight(0xffffff, 0.1, 100);
    overheadLight.position.set(0, 2, 0);
    overheadLight.target.position.set(0, 0, 0);
    scene.add(overheadLight);

    // Light point on the dresser
    const dresserLampPointLight = new THREE.PointLight(0xFFF9E5, 8, 100);
    dresserLampPointLight.position.x = -2.478;
    dresserLampPointLight.position.y = 1.04;
    dresserLampPointLight.position.z = -2.232;
    dresserLampPointLight.caseShadow = true;
    scene.add(dresserLampPointLight);

    // Floor lamp point next to the TV stand
    const floorLampPointLight = new THREE.PointLight(0xFFF9E5, 15, 100);
    floorLampPointLight.position.x = 2.197;
    floorLampPointLight.position.y = 0.9;
    floorLampPointLight.position.z = 0.2;
    floorLampPointLight.caseShadow = true;
    scene.add(floorLampPointLight);

    // Lights for focused objects...

    // Light when focusing on the synthesizer
    const synthLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    synthLight.position.x = -0.556786;
    synthLight.position.y = 0.07;
    synthLight.position.z = -1.6;
    synthLight.caseShadow = true;
    scene.add(synthLight);

    // Ligtht when focusing on the guitar case
    const guitarLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    guitarLight.position.x = 2;
    guitarLight.position.y = -0.8;
    guitarLight.position.z = -0.55;
    guitarLight.caseShadow = true;
    scene.add(guitarLight);

    // Light when focusing on the trash can
    const trashLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    trashLight.position.x = 0.6;
    trashLight.position.y = -0.7;
    trashLight.position.z = -1.04;
    trashLight.caseShadow = true;
    scene.add(trashLight);

    // Light when focusing on the robot
    const robotLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    robotLight.position.x = -1.57;
    robotLight.position.y = -0.11;
    robotLight.position.z = -1.9;
    robotLight.caseShadow = true;
    scene.add(robotLight);

    // Light when focusing on the papers located on the cork board
    const corkboardLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    corkboardLight.position.x = -2.1;
    corkboardLight.position.y = 0;
    corkboardLight.position.z = 0.3;
    corkboardLight.caseShadow = true;
    scene.add(corkboardLight);

    // Light when focusing on the blanket
    const blanketLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    blanketLight.position.x = -1.9;
    blanketLight.position.y = 0.4;
    blanketLight.position.z = -0.3;
    blanketLight.caseShadow = true;
    scene.add(blanketLight);

    // Light when focusing on the record player
    const recordPlayerLight = new THREE.PointLight(0xFFF9E5, 0, 1000);
    recordPlayerLight.position.x = 2;
    recordPlayerLight.position.y = 0.8;
    recordPlayerLight.position.z = -2.4;
    recordPlayerLight.caseShadow = true;
    scene.add(recordPlayerLight);

    // Light when focusing on the soda cans
    const sodaCansLight = new THREE.PointLight(0xFFF9E5, 0, 100);
    sodaCansLight.position.x = 0.3;
    sodaCansLight.position.y = -0.3;
    sodaCansLight.position.z = 0.8;
    sodaCansLight.caseShadow = true;
    scene.add(sodaCansLight);

    // Light when focusing on the clock
    const clockLight = new THREE.PointLight(0xFFF9E5, 0, 10);
    clockLight.position.x = -2.3;
    clockLight.position.y = 0.5;
    clockLight.position.z = -1.653;
    clockLight.caseShadow = true;
    scene.add(clockLight);

    // Light when focusing on the TV
    const tvLight = new THREE.PointLight(0xFFF9E5, 0, 10);
    tvLight.position.x = 1.6025;
    tvLight.position.y = -0.25596;
    tvLight.position.z = 1.62423;
    tvLight.caseShadow = true;
    scene.add(tvLight);


    // ================================================================================================================================
    /**
     * L O A D   M O D E L
     */
    // ================================================================================================================================

    let loadingProgress = 0;

    const loadingManager = new THREE.LoadingManager();

    // loadingManager.onStart = function(url, itemsLoaded, itemsTotal) {
    //     console.log("Started loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");
    // };

    loadingManager.onProgress = function(url, itemsLoaded, itemsTotal) {
        // console.log("Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");

        loadingProgress = Math.floor((itemsLoaded / itemsTotal) * 100);

        if (loadingProgress > 20 && loadingProgress < 40) {
            initialDialogText.innerHTML = "Warp drive activated";
        } else if (loadingProgress > 40 && loadingProgress < 60) {
            initialDialogText.innerHTML = "Wormhole created";
        } else if (loadingProgress > 60 && loadingProgress < 80) {
            initialDialogText.innerHTML = "Time vortex opened";
        } else if (loadingProgress > 80 && loadingProgress < 100) {
            initialDialogText.innerHTML = "Time travel in progress...";
        }

        // console.log("Loading progress: " + loadingProgress);

        // Update the progress bar
        progressBar.value = loadingProgress;
    };

    loadingManager.onError = function(url) {
        console.log("There was an error loading " + url);
    };

    loadingManager.onLoad = function() {
        // console.log("Loading complete!");

        initialScreenWrapper.classList.add("fade");
        setTimeout(() => {
            // Make sure that circleClip takes up the entire screen.
            // So if the screen is wider than it is tall, set the width to 100vw and the height to 100vh.
            // Otherwise, set the width to 100vh and the height to 100vw.
            if (window.innerWidth > window.innerHeight) {
                circleClip.style.width = "100vw";
                circleClip.style.height = "auto";
            } else {
                circleClip.style.width = "auto";
                circleClip.style.height = "100vw";
            }

            circleClip.style.visibility = "visible";

            setTimeout(() => {
                initialScreenWrapper.style.visibility = "hidden";

                // Set initial size
                const size = 2000000;
                circleClip.style.width = size + "px";
                circleClip.style.height = size + "px";

                setTimeout(() => {
                    // Remove the clip from the DOM
                    circleClip.remove();
                }, 1000);

                initialDialog.remove();

                // Remove the 'initial_view' class from the body
                document.body.classList.remove("initial_view");
            }, 100);
        }, 1000);

        // Start the animation loop
        animate();
    };

    // Instantiate a new gltf loader for loading the main scene and clock
    const gltfLoader = new GLTFLoader(loadingManager);

    const dLoader = new DRACOLoader();
    dLoader.setDecoderPath("../scripts/");
    dLoader.setDecoderConfig({ type: "wasm" }); // can use assemblyscript here
    gltfLoader.setDRACOLoader(dLoader);


    // Load the room scene
    gltfLoader.load("../assets/3d/portfolioRoomSimpleScaledCentered.glb", (gltf) => {
        const roomModel = gltf.scene;

        roomModel.traverse((o) => {
            if (o.isMesh) {
                o.castShadow = true;
                o.receiveShadow = true;

                if (o instanceof THREE.Object3D && o.name !== "floorLamp") {
                    o.traverse((mesh) => {
                        if (!(mesh instanceof THREE.Mesh)) return;
                    // mesh.material.side = THREE.SingleSide;
                    });
                }
            }
        });

        computerObj = roomModel.getObjectByName("Computer");

        computerObj.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        // Reference the hidden computer screen (used for focusing on the screen)
        computerScreenObj = roomModel.getObjectByName("computerScreen");

        // Reference the corkboard
        corkboardObj = roomModel.getObjectByName("corkBoard");
        corkboardObj.originalHex = corkboardObj.material.emissive.getHex();

        clockRim = roomModel.getObjectByName("clockRim");
        clockRim.originalHex = clockRim.material.emissive.getHex();
        robotObj = roomModel.getObjectByName("robot");

        clockFaceObj = roomModel.getObjectByName("clockFace");

        tvObj = roomModel.getObjectByName("tv");

        tvObj.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        hiddenTVScreenObj = roomModel.getObjectByName("tvScreen");

        synthObj = roomModel.getObjectByName("synth");

        synthObj.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        guitarCase = roomModel.getObjectByName("guitarCase");

        guitarCase.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        sodaCans = roomModel.getObjectByName("sodaCans");

        sodaCans.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        // Reference the 2 papers located on the cork board
        papersObj1 = roomModel.getObjectByName("clipboardPapers");
        papersObj1.originalHex = papersObj1.material.emissive.getHex();

        papersObj2 = roomModel.getObjectByName("clipboardPapers001");
        papersObj2.originalHex = papersObj2.material.emissive.getHex();

        blanketObj = roomModel.getObjectByName("blanketMessy");

        blanketObjMade = roomModel.getObjectByName("bedBlanketNeat");

        // Hide the made bed sheet by default
        blanketObjMade.visible = false;

        trash = roomModel.getObjectByName("trash");

        trash.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        // Reference the bed frame. We'll need to reference it when updating it's material during the bed interaction
        bedFrame = roomModel.getObjectByName("bedFrame");

        // Reference the carpet ceiling. We'll need to reference it when updating it's material during the bed interaction
        carpetCeiling = roomModel.getObjectByName("floorCeiling_3");

        // Reference the mattress. We'll need to reference it when updating it's material during the bed interaction
        mattress = roomModel.getObjectByName("bedPillow");

        // Create a new object to hold the center of the scene
        centerObj = new THREE.Object3D();

        // place it in the center of the screen
        centerObj.position.x = 0;
        centerObj.position.y = 0;
        centerObj.position.z = 0;

        // Set the object to look at to be the placeholder center object
        selectedObject = centerObj;

        roomModel.castShadow = true;
        roomModel.receiveShadow = true;

        // Clock hands
        clockHour = roomModel.getObjectByName("clockHour");
        clockMinute = roomModel.getObjectByName("clockMinute");
        clockSecond = roomModel.getObjectByName("clockSecond");

        // Remove the clockSecond from the scene. TODO! Perhaps just remove this from the actual GTLF file
        clockSecond.visible = false;

        // Clock glass material
        clockGlass = roomModel.getObjectByName("clockGlass");

        clockGlass.originalHex = clockGlass.material.emissive.getHex();

        // Create a "glass" material which will be applied to the clock glass
        const glassMaterial = new THREE.MeshPhysicalMaterial({
            roughness: 0.01,
            transmission: 1,
            thickness: 0.1
        });

        // If the clock glass exists, apply the material to it
        if (clockGlass) {
            clockGlass.traverse(function(node) {
                if (node.isMesh) {
                    node.material = glassMaterial;
                }
            });
        }

        scene.add(roomModel);
    });

    // Load the animated robot
    gltfLoader.load("../assets/3d/portfolioMoneyRobot.glb", (gltf) => {
        robotAnimated = gltf.scene;
        robotAnimations = gltf.animations;

        robotAnimated.traverse((o) => {
            if (o.isMesh) {
                o.castShadow = true;
                o.receiveShadow = true;
            }
        });

        scene.add(robotAnimated);

        // Move the robot so that it's out of the scene. We do this so that the raycaster doesn't hit it
        // when looking around the scene
        robotAnimated.position.x += 100;

        // Hide the robot
        robotAnimated.visible = false;

        robotAnimMixer = new THREE.AnimationMixer(robotAnimated);
    });

    // Load the record player
    gltfLoader.load("../assets/3d/recordPlayer.glb", (gltf) => {
        const model = gltf.scene;
        recordPlayerAnimations = gltf.animations;

        model.traverse((o) => {
            if (o.isMesh) {
                o.castShadow = true;
                o.receiveShadow = true;
            }
        });

        recordPlayer = model.getObjectByName("recordPlayer");

        recordPlayer.children.forEach((child) => {
            child.originalHex = child.material.emissive.getHex();
        });

        recordPlayerCoverObj = model.getObjectByName("recordPlayerCover");
        recordPlayerCoverObj.originalHex = recordPlayerCoverObj.material.emissive.getHex();

        cassetteCoverObj = model.getObjectByName("cassetteCover");
        cassetteCoverObj.originalHex = cassetteCoverObj.material.emissive.getHex();

        // Create the plastic material that will be applied to the recover player cover
        const plasticRecordCoverMaterial = new THREE.MeshPhysicalMaterial({
            roughness: 0.1,
            transmission: 0.999,
            thickness: 0.1,
            color: 0xEDEDED
        });

        // Apply the plastic material to the record player cover
        if (recordPlayerCoverObj) {
            recordPlayerCoverObj.traverse(function(node) {
                if (node.isMesh) {
                    node.material = plasticRecordCoverMaterial;

                    // Make it double sided so that we can see the inside of the cover
                    node.material.side = THREE.DoubleSide;
                }
            });
        }

        // Create the plastic material that will be applied to the cassette cover
        const plasticMaterial = new THREE.MeshPhysicalMaterial({
            roughness: 0.1,
            transmission: 0.99,
            thickness: 0.2,
            // Tint to be brownish
            color: 0x978468
        });

        // Apply the plastic material to the cassette cover
        if (cassetteCoverObj) {
            cassetteCoverObj.traverse(function(node) {
                if (node.isMesh) {
                    node.material = plasticMaterial;
                }
            });
        }

        scene.add(model);

        recordPlayerAnimMixer = new THREE.AnimationMixer(model);
    });

    // Load the interactive synthesizer
    gltfLoader.load("../assets/3d/synth.glb", (gltf) => {
        interactiveSynth = gltf.scene;

        interactiveSynth.traverse((o) => {
            if (o.isMesh) {
                o.castShadow = true;
                o.receiveShadow = true;
            }
        });

        // Reference the keys
        keyF0 = interactiveSynth.getObjectByName("keyF0");
        keyFS0 = interactiveSynth.getObjectByName("keyFS0");
        keyG0 = interactiveSynth.getObjectByName("keyG0");
        keyGS0 = interactiveSynth.getObjectByName("keyGS0");
        keyA0 = interactiveSynth.getObjectByName("keyA0");
        keyAS0 = interactiveSynth.getObjectByName("keyAS0");
        keyB0 = interactiveSynth.getObjectByName("keyB0");
        keyC = interactiveSynth.getObjectByName("keyC");
        keyCS = interactiveSynth.getObjectByName("keyCS");
        keyD = interactiveSynth.getObjectByName("keyD");
        keyDS = interactiveSynth.getObjectByName("keyDS");
        keyE = interactiveSynth.getObjectByName("keyE");
        keyF = interactiveSynth.getObjectByName("keyF");
        keyFS = interactiveSynth.getObjectByName("keyFS");
        keyG = interactiveSynth.getObjectByName("keyG");
        keyGS = interactiveSynth.getObjectByName("keyGS");
        keyA = interactiveSynth.getObjectByName("keyA");
        keyAS = interactiveSynth.getObjectByName("keyAS");
        keyB = interactiveSynth.getObjectByName("keyB");
        keyC2 = interactiveSynth.getObjectByName("keyC2");
        keyCS2 = interactiveSynth.getObjectByName("keyCS2");
        keyD2 = interactiveSynth.getObjectByName("keyD2");
        keyDS2 = interactiveSynth.getObjectByName("keyDS2");
        keyE2 = interactiveSynth.getObjectByName("keyE2");
        keyF2 = interactiveSynth.getObjectByName("keyF2");
        keyFS2 = interactiveSynth.getObjectByName("keyFS2");
        keyG2 = interactiveSynth.getObjectByName("keyG2");
        keyGS2 = interactiveSynth.getObjectByName("keyGS2");
        keyA2 = interactiveSynth.getObjectByName("keyA2");
        keyAS2 = interactiveSynth.getObjectByName("keyAS2");
        keyB2 = interactiveSynth.getObjectByName("keyB2");

        // Move the synthesize so that it's out of the scene. We do this so that the racyaster doesn't hit it
        // when looking around the scene
        interactiveSynth.position.x += 100;

        // Hide the synthesizer
        interactiveSynth.visible = false;

        scene.add(interactiveSynth);
    });


    // ================================================================================================================================
    /**
     * C O M P U T E R   S C R E E N S A V E R
     */
    // ================================================================================================================================

    const createScreensaver = () => {
    // Create a new plane geometry that overlays on top of the computer screen
        const planeGeometry = new THREE.PlaneGeometry(0.338, 0.25);

        // Add a background image to the plane
        const loader = new THREE.TextureLoader();

        loader.load("../assets/images/screensaver/1.jpg", (texture) => {
            const material = new THREE.MeshBasicMaterial({
                map: texture,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 1,
                depthWrite: false
            });

            const plane = new THREE.Mesh(planeGeometry, material);

            // Set the name of the plane
            plane.name = "screensaver";

            // Set the position of the plane
            plane.position.x = 0.77073;
            plane.position.y = -0.18203;
            plane.position.z = -1.835;

            // Add the plane to the scene
            scene.add(plane);
        });
    };


    // ================================================================================================================================
    /**
 * C L O S E T  M I R R O R S
 */
    // ================================================================================================================================

    // Create the geometry template for a mirror
    const mirrorGeometry = new THREE.BoxGeometry(1.88, 1.8, 0.001);

    // Used to check if the device is low performance. We'll use this to determine if we should limit the quality of the reflection
    const isLowPerformance = () => {
        // Check if the browser supports the Performance API
        if (window.performance) {
            // Get the device's hardware concurrency (number of logical processors)
            const hardwareConcurrency = navigator.hardwareConcurrency;

            // Check if the hardware concurrency is low
            if (hardwareConcurrency && hardwareConcurrency <= 2) {
                return true;
            }
        }

        return false;
    };

    // Using the mirror geometry, create a new mirror as a reflector
    const closetMirror = new Reflector(mirrorGeometry, {
        clipBias: 0.0003,
        textureWidth: isLowPerformance() ? (window.innerWidth * .5) * (window.devicePixelRatio * .5) : window.innerWidth * window.devicePixelRatio,
        textureHeight: isLowPerformance() ? (window.innerHeight * .5) * (window.devicePixelRatio  * .5) : window.innerHeight * window.devicePixelRatio,
        recursion: 1,
        color: 0xB9B9B9
    });

    // Set the location of the first mirror and add it to the scene
    closetMirror.position.x = -0.01;
    closetMirror.position.y = -0.35;
    closetMirror.position.z = 2.842;
    closetMirror.rotateY(-Math.PI / 1) ;

    scene.add(closetMirror);

    // ================================================================================================================================
    /**
     * H E L P E R  F U N C T I O N S
     */
    // ================================================================================================================================

    // Called when in view mode of the robot and the robot is clicked on.
    // This will switch out the robot objects and play the animation.
    const robotInteraction = () => {
    // If we've already activated the robot animation, then its x position will be 0
    // If so, don't do anything
        if (robotAnimated.position.x === 0) return;

        // Remove the overlay
        robotOverlay.classList.add("hidden");

        // Move the animated robot into view and show it
        robotAnimated.position.x -= 100;
        robotAnimated.visible = true;

        // Hide the original robot object
        robotObj.visible = false;

        // Wait a second and then play the robot animation
        setTimeout(() => {
            robotAnimations.forEach((clip) => {
                robotAnimMixer.clipAction(clip).play();
            });
        }, 1000);
    };

    // Called when in view mode of the trash can and the trash can is clicked on.
    // This will open the old portfolio in a new tab and then go back to the navigation view.
    const trashCanInteraction = () => {
    // Otherwise, when clicking on the trash can, open the old portfolio in a new tab
        window.open("https://www.helloimdylan.com/projects.html", "_blank");

        // Hide the trash can overlay
        trashOverlay.classList.add("hidden");

        // Go back to the original camera position
        exitFocus();
    };

    // Rain down a bunch of musical instruments from the top of the screen when focusing on the guitar case
    const rainInstruments = () => {
    // Set to true so that TWEEN is updated every render loop
        isRainingInstruments = true;

        let totalComplete = 0;

        for (let i = 0; i <= 200; i++) {
        // Randomly rain down the instrument images on the screen
            const randomInstrument = Math.floor(Math.random() * 16);

            let instrumentImage = null;
            let width;

            switch (randomInstrument) {
                case 0:
                    instrumentImage = synthImage.cloneNode(true);
                    width = 200;
                    break;
                case 1:
                    instrumentImage = guitarImage.cloneNode(true);
                    width = 100;
                    break;
                case 2:
                    instrumentImage = mandolinImage.cloneNode(true);
                    width = 70;
                    break;
                case 3:
                    instrumentImage = celloImage.cloneNode(true);
                    width = 100;
                    break;
                case 4:
                    instrumentImage = bassImage.cloneNode(true);
                    width = 100;
                    break;
                case 5:
                    instrumentImage = guitarMetalImage.cloneNode(true);
                    width = 100;
                    break;
                case 6:
                    instrumentImage = mandolinLightImage.cloneNode(true);
                    width = 70;
                    break;
                case 7:
                    instrumentImage = celloWhiteImage.cloneNode(true);
                    width = 100;
                    break;
                case 8:
                    instrumentImage = bassRedImage.cloneNode(true);
                    width = 100;
                    break;
                case 9:
                    instrumentImage = celloCarbon.cloneNode(true);
                    width = 100;
                    break;
                case 10:
                    instrumentImage = bassGreen.cloneNode(true);
                    width = 100;
                    break;
                case 11:
                    instrumentImage = guitarClassical.cloneNode(true);
                    width = 100;
                    break;
                case 12:
                case 13: // Twice as often
                    instrumentImage = saxophone.cloneNode(true);
                    width = 100;
                    break;
                case 14:
                    instrumentImage = electricGuitarGold.cloneNode(true);
                    width = 100;
                    break;
                case 15:
                    instrumentImage = electricGuitarRed.cloneNode(true);
                    width = 100;
                    break;
                default:
            // Do nothing
            }

            // Create a new div
            const instrument = document.createElement("div");

            // Add the class to the div
            instrument.classList.add("musical_instrument");

            // Set a random left position somewhere within the screen space
            instrument.style.left = `${Math.floor(Math.random() * 100)}vw`;
            instrument.style.top = "-500px";

            // Randomly rotate the image between -90 and 90 degrees
            instrument.style.transform = `rotate(${Math.floor(Math.random() * 180) - 90}deg)`;

            // Override the width of the image subtracting anywhere between 1 and 60 pixels
            instrumentImage.style.width = `${width - Math.floor(Math.random() * 60)}px`;

            // Add the div to the body after the guitar info overlay
            // document.body.insertAfter(instrument, guitarInfo);

            guitarInfo.insertAdjacentElement("afterend", instrument);

            // Add the image to the div
            instrument.appendChild(instrumentImage);

            const initialPosition = { y: -500 };
            const targetPosition = { y: window.innerHeight - instrument.clientHeight + 200 };

            // Random time to tween the instrument to the bottom of the screen between half a second and 3 seconds
            const randomTime = Math.floor(Math.random() * 2500) + 500;

            // Set a random delay between 0 and 2 seconds
            const randomDelay = Math.floor(Math.random() * 2000);

            // Randomly decide if the instrument should be rotated clockwise or counter-clockwise
            const randomRotation = Math.floor(Math.random() * 2);

            // Decide the rotation speed between 0.1 and 2.5 degrees
            const rotationSpeed = Math.floor(Math.random() * 2.4) + 0.1;

            // Starting rotation
            let rotation = 0;

            // Tween the instrument to the bottom of the screen, also rotating it as it "falls"
            new TWEEN.Tween(initialPosition)
                .to(targetPosition, randomTime)
                .delay(randomDelay)
                .easing(TWEEN.Easing.Quadratic.In)
                .onUpdate(() => {
                // Update the image position during the tween
                    instrument.style.top = initialPosition.y + "px";

                    if (randomRotation === 0) {
                        rotation -= rotationSpeed;
                    } else {
                        rotation += rotationSpeed;
                    }
                    instrument.style.transform = `rotate(${rotation}deg)`;
                })
                .onComplete(() => {
                // Remove the instrument from the DOM when it's done raining
                    document.body.removeChild(instrument);

                    totalComplete++;

                    // If all of them are done "raining", then no longer update TWEEN
                    if (totalComplete >= 200) {
                    //... But we need to waitn an extra moment for the last one to be removed from the DOM
                        setTimeout(() => {
                            isRainingInstruments = false;
                        }, 1000);
                    }
                })
                .start(); // Start the tween

            // Show the info overlay
            guitarInfo.classList.remove("hidden");
        }
    };

    // Called when in view mode of the bed and the blanket is clicked on.
    // This will switch out the blanket objects, make the bed, and go back to the navigation view.
    const makeTheBed = () => {
    // Hide the blanket overlay
        blanketOverlay.classList.add("hidden");

        // Hide the messy blanket
        blanketObj.visible = false;

        // Don't allow the user to be able to click on the blanket again
        blanketInViewMode = false;
        bedMade = true;

        // Move the made bed into the scene
        blanketObjMade.position.y -= 2;

        // Show the made bed
        blanketObjMade.visible = true;

        // Change the bed frame material so that the messy blanket's shadow isn't visible
        bedFrame.material.map = bedFrameTexture;
        bedFrame.material.map.colorSpace = "srgb";
        bedFrame.material.needsUpdate = true;

        // Change the carpet ceiling material so that the messy blanket's shadow isn't visible
        carpetCeiling.material.map = carpetCeilingTexture;
        carpetCeiling.material.map.colorSpace = "srgb";
        carpetCeiling.material.needsUpdate = false;

        // Change the mattress material so that the messy blanket's shadow isn't visible
        mattress.material.map = mattressTexture;
        mattress.material.map.colorSpace = "srgb";
        mattress.material.needsUpdate = false;

        // Wait a half second and then go back to the navigation view
        setTimeout(() => {
        // Go back to the original camera position
            exitFocus();
        }, 500);
    };

    // When the resume paper is clicked, we'll show the resume in the corkboard overlay
    const showResume = () => {
    // Show the corkboard overlay
        corkboardOverlay.classList.remove("hidden");

        // Remove everything inside the corkboard overlay's inner wrapper
        corkboardOverlayInner.innerHTML = "";

        // Scroll to the top of the overlay inner wrapper
        corkboardOverlayInner.scrollTop = 0;

        // Create a new image
        const resumeImage = document.createElement("img");

        // Set the source of the image
        resumeImage.src = "../assets/docs/dylanHepworthResume24.jpg";

        // Add the button to open the resume in a new tab
        const openResumeBtn = document.createElement("button");
        openResumeBtn.innerHTML = "Download Resume";

        resumeBtn = openResumeBtn;

        // Add the image to the inner wrapper
        corkboardOverlayInner.appendChild(resumeImage);

        // Add the button to the inner wrapper
        corkboardOverlayInner.appendChild(resumeBtn);
    };

    // When the about paper is clicked, we'll show the about text in the corkboard overlay
    const showAbout = () => {
    // Show the corkboard overlay
        corkboardOverlay.classList.remove("hidden");

        // Remove everything inside the corkboard overlay's inner wrapper
        corkboardOverlayInner.innerHTML = "";

        // Scroll to the top of the overlay inner wrapper
        corkboardOverlayInner.scrollTop = 0;

        // Content template
        const innerText = `
        <div class="about_txt">
            <h2>About Dylan</h2>
            <h3>ANALYTICAL + CREATIVE WORKING TOGETHER</h3>
            <br />
            <p>Greetings! I'm Dylan, a seasoned software engineer and manager deeply passionate about crafting innovative solutions. Born in Seattle to an American mother and English father, I hold dual citizenship. Despite my parents' lack of interest in computer sciences, my tech-savvy older brothers sparked my early fascination. My initiation into coding began with a RadioShack Tandy 1000, leading to the creation of my first basic game (written in BASIC!).</p>
            <p>Growing up in an environment supportive of artistic pursuits, thanks to my father's background in pop art, I explored various creative outlets. Exposure to early Macintosh models in school left a lasting impression, inspiring my early design experiments.</p>
            <p>Initially drawn to architecture, I spent two years in school studying the field and honing my AutoCAD skills. However, the realization that my focus would shift towards building codes rather than actual design led me to reevaluate my career path.</p>
            <p>My journey into web development began when a friend asked me to build a website. Encouraged by my oldest brother, I delved into coding with a borrowed VB.net book, later transitioning to C#. Over a decade has passed since, and while C# remains a hobby for projects like Unity game development, my primary focus has shifted to modern web technologies like React, TypeScript, and Node.js. This portfolio serves as a platform to present a compilation of my ideas and interests in both design and development.</p>
        </div>
    `;

        // Add the content to the inner wrapper
        corkboardOverlayInner.innerHTML = innerText;
    };

    // Update the clock face based on the number passed in (1, 2, or 3) 
    const updateClockFace = (clockFaceNum) => {
        let choice = clockFace1;

        if (clockFaceNum === 2) {
            choice = clockFace2;
        } else if (clockFaceNum === 3) {
            choice = clockFace3;
        }

        // Remove the selected class from all the choices
        clockFace1.classList.remove("selected");
        clockFace2.classList.remove("selected");
        clockFace3.classList.remove("selected");

        // Add the selected class to the clicked choice
        choice.classList.add("selected");

        switch (clockFaceNum) {
            case 1:
            // Dad's watch colors
                clockHour.material.color.setHex(10479103);
                clockMinute.material.color.setHex(16774784);
                clockSecond.material.color.setHex(16748961);

                // Update the clock face texture
                clockFaceObj.material.map = clockFaceImage1;
                break;
            case 2:
            // Retro 80s
                clockHour.material.color.setHex(0x0084FD);
                clockMinute.material.color.setHex(0x00FDC0);
                clockSecond.material.color.setHex(0xFFF500);

                clockFaceObj.material.map = clockFaceImage2;
                break;
            case 3:
            // Black and white clock
                clockHour.material.color.setHex(0x000000);
                clockMinute.material.color.setHex(0x000000);
                clockSecond.material.color.setHex(0xAD0000);

                clockFaceObj.material.map = clockFaceImage3;
                break;
            default:
        // Do nothing
        }

        clockFaceObj.material.map.colorSpace = "srgb";
    };

    // Leave the focus mode view and return to standard navigation (centered in the room)
    const exitFocus = () => {
    // Hide the UI bar
        uiWrapper.classList.remove("show");

        isFocusingOnObject = true;

        // If we're looking at the TV, hide the TV screen
        if (selectedObject === hiddenTVScreenObj) {
            tvScreen.style.visibility = "hidden";
        }

        // Which item are we looking at?
        const lookingAt = selectedObject;

        let lightToDescrease = null;

        switch (lookingAt.name) {
            case "robot":
                lightToDescrease = robotLight;
                break;
            case "trash":
                lightToDescrease = trashLight;
                break;
            case "sodaCans":
                lightToDescrease = sodaCansLight;

                // Hide the soda cans info overlay
                sodaCansInfo.classList.add("hidden");
                break;
            case "guitarCase":
                lightToDescrease = guitarLight;

                // Hide the guitar info overlay
                guitarInfo.classList.add("hidden");
                break;
            case "synth":
                lightToDescrease = synthLight;
                break;
            case "corkBoard":
                lightToDescrease = corkboardLight;
                break;
            case "recordPlayer":
                lightToDescrease = recordPlayerLight;
                break;
            case "blanketMessy":
                lightToDescrease = blanketLight;
                break;
            case "clockRim":
                lightToDescrease = clockLight;
                break;
            default:
            // Do nothing
        }

        // If we are moving back from being focused on the TV, then the game controller icon is still showing. Remove it.
        const controllerIcon = document.querySelector(".controller_icon");
        if (controllerIcon) {
        // Remove the controller icon
            document.body.removeChild(controllerIcon);
        }

        // Special case for the TV
        if (lookingAt.name === "tvScreen") {
            lightToDescrease = tvLight;
        }

        // If there was a light to decrease, do so
        if (lightToDescrease) {
        // Tween the light intensity to 0
            new TWEEN.Tween(lightToDescrease)
                .to({ intensity: 0 }, 1000)
                .start();
        }

        // Call the callback if it exists
        if (resetCameraCallback) resetCameraCallback();

        new TWEEN.Tween({
            x: camera.position.x,
            y: camera.position.y,
            z: camera.position.z
        }).to({ x: lastCameraPosition.x, y: lastCameraPosition.y, z: lastCameraPosition.z }, 1000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate((coords) => {
                camera.position.x = coords.x;
                camera.position.y = coords.y;
                camera.position.z = coords.z;
            }).onComplete(() => {
                controls.enabled = false;

                selectedObject  = centerObj;
                camera.target = new THREE.Vector3(selectedObject.position.x, selectedObject.position.y, selectedObject.position.z);

                // backup original rotation
                const startRotation = new THREE.Euler().copy(camera.rotation);

                // final rotation (with lookAt)
                camera.lookAt(camera.target);
                const endRotation = new THREE.Euler().copy(camera.rotation);

                // revert to original rotation
                camera.rotation.copy(startRotation);

                // Tween
                new TWEEN.Tween(camera.rotation)
                    .to({
                        x: endRotation.x,
                        y: endRotation.y,
                        z: endRotation.z
                    }, 500)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .onComplete(() => {
                        controls.enabled = true;

                        // Allow to hover over objects again
                        canHover = true;

                        isFocusingOnObject = false;

                        // If all items have been discovered, and we haven't shown the overlay before, show the success overlay and make confetti
                        if (Object.values(discoverables).every((item) => item === true) && successOverlayShown === false) {
                        // Don't show the overlay again
                            successOverlayShown = true;

                            // Show the success overlay
                            successOverlay.classList.remove("hidden");

                            // Make confetti
                            makeConfetti();

                            setTimeout(() => {
                                makeConfetti();
                            }, 300);

                            setTimeout(() => {
                                makeConfetti();
                            }, 600);
                        }
                    }).start();
            }).start();
    };

    const addComputerScreen = () => {
        // Create a new div
        computerScreen = document.createElement("div");

        // Add the class to the div
        computerScreen.classList.add("computer_screen");

        // Add the div to the body
        document.body.appendChild(computerScreen);

        // Set the correct width of the div
        resizeComputerScreen();

        // Add a new iframe
        const iframe = document.createElement("iframe");

        // Add a source to the iframe
        iframe.src = "../dilux/index.html";

        // Add an ID to the iframe
        iframe.id = "dilux_iframe";

        // Remove the border
        iframe.frameBorder = 0;

        // Make it the same width and height as the div
        iframe.width = "100%";
        iframe.height = "100%";

        // Add the iframe to the div
        computerScreen.appendChild(iframe);
    };

    const makeConfetti = () => {
        for (let i = 0; i < 200; i++) {
        // Random number between 1 and 7
            const randomConfetti = Math.floor(Math.random() * 7) + 1;

            let confettiImg;

            switch (randomConfetti) {
                case 1:
                // Make a copy of the first confetti shape
                    confettiImg = confetti1.cloneNode(true);
                    break;
                case 2:
                // Make a copy of the second confetti shape
                    confettiImg = confetti2.cloneNode(true);
                    break;
                case 3:
                // Make a copy of the third confetti shape
                    confettiImg = confetti3.cloneNode(true);
                    break;
                case 4:
                // Make a copy of the fourth confetti shape
                    confettiImg = confetti4.cloneNode(true);
                    break;
                case 5:
                // Make a copy of the fifth confetti shape
                    confettiImg = confetti5.cloneNode(true);
                    break;
                case 6:
                // Make a copy of the sixth confetti shape
                    confettiImg = confetti6.cloneNode(true);
                    break;
                case 7:
                // Make a copy of the seventh confetti shape
                    confettiImg = confetti7.cloneNode(true);
                    break;
                default:
            // Do nothing
            }

            // Create a new div
            const confettiPiece = document.createElement("div");

            // Add the class to the div
            confettiPiece.classList.add("confetti");

            // Add the image to the div
            confettiPiece.appendChild(confettiImg);

            const initialPosition = { y: (window.innerHeight / 2), x: (window.innerWidth / 2) };

            // Create a random y position somwhere within the confines of the screen
            const randomY = `${Math.floor(Math.random() * window.innerHeight)}px`;

            // Create a random x position somwhere within the confines of the screen
            const randomX = `${Math.floor(Math.random() * window.innerWidth)}px`;

            // Move the confetti piece up 10% of the screen height
            const targetPosition = { y: randomY, x: randomX };

            // Random time to tween the confetti to the bottom of the screen between a quarter of a second and a second
            const randomTime = Math.floor(Math.random() * 750) + 250;

            // Add the confetti piece to the body
            document.body.appendChild(confettiPiece);

            // Randomly rotate the image between -90 and 90 degrees
            const initialRotation = `rotate(${Math.floor(Math.random() * 180) - 90}deg)`;

            confettiPiece.style.transform = initialRotation;

            // Tween the confetti piece to the target position
            new TWEEN.Tween(initialPosition)
                .to(targetPosition, randomTime)
                .easing(TWEEN.Easing.Quadratic.In)
                .onUpdate(() => {
                // Update the confetti piece position during the tween
                    confettiPiece.style.top = initialPosition.y + "px";
                    confettiPiece.style.left = initialPosition.x + "px";

                    confettiPiece.style.transform += ` rotate(${Math.floor(Math.random() * 180) - 90}deg)`;
                })
                .onComplete(() => {
                // Remove the confetti piece from the DOM when it's done raining
                    document.body.removeChild(confettiPiece);
                })
                .start(); // Start the tween
        }
    };

    // When clicking on a sythesizer black or white key, animate the key and play a sound
    const playSynthSound = (key) => {
    // If the key exists...
        if (key) {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();

            // Create an oscillator node (sawtooth wave)
            const oscillator = audioContext.createOscillator();
            oscillator.type = "triangle";

            let frequency = 0;

            // Decide which key to play
            switch (key.name) {
                case "keyF0":
                    frequency = 82.41;
                    break;
                case "keyFS0":
                    frequency = 87.31;
                    break;
                case "keyG0":
                    frequency = 92.50;
                    break;
                case "keyGS0":
                    frequency = 103.83;
                    break;
                case "keyA0":
                    frequency = 110.00;
                    break;
                case "keyAS0":
                    frequency = 116.54;
                    break;
                case "keyB0":
                    frequency = 123.47;
                    break;
                case "keyC":
                    frequency = 130.81;
                    break;
                case "keyCS":
                    frequency = 138.59;
                    break;
                case "keyD":
                    frequency = 146.83;
                    break;
                case "keyDS":
                    frequency = 155.56;
                    break;
                case "keyE":
                    frequency = 164.81;
                    break;
                case "keyF":
                    frequency = 174.61;
                    break;
                case "keyFS":
                    frequency = 185.00;
                    break;
                case "keyG":
                    frequency = 196.00;
                    break;
                case "keyGS":
                    frequency = 207.65;
                    break;
                case "keyA":
                    frequency = 220.00;
                    break;
                case "keyAS":
                    frequency = 233.08;
                    break;
                case "keyB":
                    frequency = 246.94;
                    break;
                case "keyC2":
                    frequency = 261.63;
                    break;
                case "keyCS2":
                    frequency = 277.18;
                    break;
                case "keyD2":
                    frequency = 293.66;
                    break;
                case "keyDS2":
                    frequency = 311.13;
                    break;
                case "keyE2":
                    frequency = 329.63;
                    break;
                case "keyF2":
                    frequency = 349.23;
                    break;
                case "keyFS2":
                    frequency = 369.99;
                    break;
                case "keyG2":
                    frequency = 392.00;
                    break;
                case "keyGS2":
                    frequency = 415.30;
                    break;
                case "keyA2":
                    frequency = 440.00;
                    break;
                case "keyAS2":
                    frequency = 466.16;
                    break;
                case "keyB2":
                    frequency = 493.88;
                    break;
                default:
            // Do nothing
            }

            oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);

            // Connect the oscillator to the audio context's destination (speakers)
            oscillator.connect(audioContext.destination);

            // Start the oscillator
            oscillator.start();

            // Stop the oscillator after a certain duration (e.g., 1 second)


            // Rotate the key on the local x axis by 10 degrees
            new TWEEN.Tween(key.rotation)
                .to({ x: 0.05 }, 100)
                .onComplete(() => {
                // Rotate the key back to it's original position
                    new TWEEN.Tween(key.rotation)
                        .to({ x: 0 }, 300)
                        .onComplete(() => {
                            oscillator.stop();
                        })
                        .start();
                })
                .start();
        }
    };

    // Reset all hex (hover) values for every object. Used when no longer hovering over an object with the mouse.
    const resetAllHex = () => {
        clockRim.material.emissive.setHex(clockRim.originalHex);
        clockGlass.material.emissive.setHex(clockGlass.originalHex);

        // Get the tv screen saver
        const tvScreenSaver = scene.getObjectByName("tvScreensaver");

        if (tvScreenSaver) {
            tvScreenSaver.material?.emissive.setHex(tvScreenSaver.originalHex);
        }

        computerObj.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });

        synthObj.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });

        recordPlayerCoverObj.material.emissive.setHex(recordPlayerCoverObj.originalHex);

        cassetteCoverObj.material.emissive.setHex(cassetteCoverObj.originalHex);

        corkboardObj.material.emissive.setHex(corkboardObj.originalHex);

        papersObj1.material.emissive.setHex(corkboardObj.originalHex);

        papersObj2.material.emissive.setHex(papersObj2.originalHex);

        tvObj.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });

        guitarCase.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });

        recordPlayer.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });

        sodaCans.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });

        trash.children.forEach((child) => {
            child.material.emissive.setHex(child.originalHex);
        });
    };

    // Called when focusing on an object. Checks to see if the item has been discovered yet.
    // If not, update the text in the UI bar.
    const updateDiscoverables = (name) => {
        if (!discoverables[name]) {
            discoverables[name] = true;

            // How many items in the discoverables object are true?
            const numDiscovered = Object.values(discoverables).filter((item) => item === true).length;

            // Update the UI bar
            starTooltipText.innerHTML = `${numDiscovered} / 11`;
        }
    };

    const addTVScreen = () => {
    // Create a new div
        tvScreen = document.createElement("div");

        // Add the class to the div
        tvScreen.classList.add("tv_screen");

        // Add the div to the body
        document.body.appendChild(tvScreen);

        // Set the correct width of the div
        resizeTVScreen();
    };

    const addGameIframe = () => {
        let iframe;
        let doAppend = true;

        const showAndFocus = () => {
        // Give the iframe a moment to load everything to the canvas before showing it. This should ensure a
        // smooth transition without any screen "flashing".
            setTimeout(() => {
            // Add the "show" class to the iframe. This will trigger the opacity to fade in
                iframe.classList.add("show");

                // Focus on the iframe
                iframe.contentWindow.focus();
            }, 600);
        };

        // First check to see if the iframe has already been added
        if (!tvScreen.querySelector("iframe")) {
        // Create a new iframe
            iframe = document.createElement("iframe");

            // Add a source to the iframe
            iframe.src = "../elsonador/index.html";
        } else {
            iframe = tvScreen.querySelector("iframe");
            doAppend = false;
        }

        // Remove the border
        iframe.frameBorder = 0;

        // Make it the same width and height as the div
        iframe.width = "100%";
        iframe.height = "100%";

        // Add the ID to the iframe
        iframe.id = "elsonador_iframe";

        if (doAppend) {
        // Add the iframe to the div
            tvScreen.appendChild(iframe);
        } else {
            iframe.contentWindow.postMessage("START_GAME", window.location.protocol + "//" + window.location.host + "/");
        }

        showAndFocus();

        // Delay adding the controls overlay so that the game has a moment to load
        setTimeout(() => {
        // Show the controls overlay
            if (controlsVisible) {
                controlsWrapper.classList.remove("hidden");
            } else {
                addControllerIcon();
            }
        }, 500);

        // Delay a moment to let the tv light to turn on
        setTimeout(() => {
        // Don't allow constant rendering (painting) while focusing on the computer
            canRender = false;
        }, 1000);
    };

    const pauseGameIframe = () => {
        // Send a message to the iframe to pause the game
        const tvScreenWrapper = document.querySelector(".tv_screen");

        const iframe = tvScreenWrapper.querySelector("iframe");

        // Get the window object of the iframe
        const iframeWindow = iframe.contentWindow;
        // Trigger JavaScript code within the iframe
        iframeWindow.postMessage("PAUSE_GAME", window.location.protocol + "//" + window.location.host + "/");

        // TODO! Need to actually pause the game and make sure that it's not running the animation loop constantly (until we unpause it)
    };

    const resizeComputerScreen = () => {
        const computedStyle = window.getComputedStyle(computerScreen);

        const screenWidth = computedStyle.height.replace("px", "") * 1.35 + "px";

        computerScreen.style.width = screenWidth;
    };

    const resizeTVScreen = () => {
        const computedStyle = window.getComputedStyle(tvScreen);

        const screenWidth = computedStyle.height.replace("px", "") * 1.3 + "px";

        tvScreen.style.width = screenWidth;
    };

    const addControllerIcon = () => {
        // Add the controller icon to the bottom ui wrapper so that the user can click it to access the controller overlay again.
        // Create a new image element
        const controllerIcon = document.createElement("img");
        controllerIcon.src= "../assets/images/controllerIcon.svg";

        // Add the class to the image
        controllerIcon.classList.add("controller_icon");

        // Add the image to the page
        document.body.appendChild(controllerIcon);

        // Now add an event listener to the controller icon so that when the user clicks on it, the controls overlay is shown again
        controllerIcon.addEventListener("click", () => {
        // Remove the controller icon
            document.body.removeChild(controllerIcon);

            // Show the controls overlay
            controlsWrapper.classList.remove("hidden");
        });
    };

    // ================================================================================================================================
    /**
     * F O C U S   O N   O B J E C T S
     */
    // ================================================================================================================================

    // Main helper function for focusing on an object
    const focusOnObject = (focusObj, targetCoords, cb, lightingInfo) => {
        controls.enabled = false;

        // Allow rendering during the transition
        isFocusingOnObject = true;

        // Don't allow to hover over objects while focusing on something
        canHover = false;

        // Get the current camera position
        lastCameraPosition = {
            x: camera.position.x,
            y: camera.position.y,
            z: camera.position.z
        };

        // Focus on the computer screen
        selectedObject = focusObj;

        camera.target = new THREE.Vector3(selectedObject.position.x, selectedObject.position.y, selectedObject.position.z);

        // Backup original rotation from wherever the camera is currently looking
        const startRotation = new THREE.Euler().copy(camera.rotation);

        // Final rotation (while looking at the target)
        camera.lookAt(camera.target);
        const endRotation = new THREE.Euler().copy(camera.rotation);

        // Revert to original rotation
        camera.rotation.copy(startRotation);

        // Start by focusing on the object
        new TWEEN.Tween(camera.rotation)
            .to({
                x: endRotation.x,
                y: endRotation.y,
                z: endRotation.z
            }, 500)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => {
            // Now move to the locaiton of the object for optimal viewing
                new TWEEN.Tween({
                    x: camera.position.x,
                    y: camera.position.y,
                    z: camera.position.z
                }).to({ x: targetCoords.x, y: targetCoords.y, z: targetCoords.z }, 1000)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .onUpdate((coords) => {
                        camera.position.x = coords.x;
                        camera.position.y = coords.y;
                        camera.position.z = coords.z;
                    })
                    .onComplete(() => {
                    // If there was any lighting info passed in, apply it
                        if (lightingInfo) {
                        // Tween the light intensity to 8
                            new TWEEN.Tween(lightingInfo.lightObj)
                                .to({ intensity: lightingInfo.intensity }, 1000)
                                .start();
                        }

                        setTimeout(() => {
                        // Show the ui bar
                            uiWrapper.classList.add("show");
                        }, 300);

                        setTimeout(() => {
                            if (cb) cb();
                        }, 400);

                        setTimeout(() => {
                        // No longer focusing on an object. This will stop rendering.
                            isFocusingOnObject = false;
                        }, 1000);
                    })
                    .start();
            })
            .start();
    };

    const focusSynth = () => {
        // Update the discoverables
        updateDiscoverables("synth");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {
            synthInViewMode = false;

            // Move the interactive synth out of view and hide it
            interactiveSynth.position.x += 100;
            interactiveSynth.visible = false;

            // Move the static synth object into view and show it
            synthObj.visible = true;
        };

        focusOnObject(synthObj, {
            x: -0.556786,
            y: 0.07,
            z: -1.6
        }, () => {
            synthInViewMode = true;
            canHover = true;
            canRaycast = true;

            // Move the interactive synth into view and show it
            interactiveSynth.position.x -= 100;
            interactiveSynth.visible = true;

            synthObj.visible = false;
        }, {
            lightObj: synthLight,
            intensity: 8
        });
    };

    const focusGuitarCase = () => {
        // Update the discoverables
        updateDiscoverables("guitar");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {};

        focusOnObject(guitarCase, {
            x: 1.5,
            y: -0.8,
            z: 0.5
        }, () => {
        // First, make sure that we reset the scroll position of the 
            guitarInfo.querySelector(".inner_wrapper").scrollTop = 0;

            rainInstruments();
        }, {
            lightObj: guitarLight,
            intensity: 8
        });
    };

    const focusTrashCan = () => {
        // Update the discoverables
        updateDiscoverables("trash");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {
            trashInViewMode = false;

            // Hide the trash can overlay
            trashOverlay.classList.add("hidden");
        };

        focusOnObject(trash, {
            x: 0.6,
            y: -0.7,
            z: -1.04
        }, () => {
        // Set the appropriate flags that the trash can is in view so that we can click on it to open the old portfolio
            trashInViewMode = true;
            canHover = true;
            canRaycast = true;

            // Show the overlay
            trashOverlay.classList.remove("hidden");
        }, {
            lightObj: trashLight,
            intensity: 4
        });
    };

    const focusRecordPlayer = () => {
        // Update the discoverables
        updateDiscoverables("record");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {
            recordPlayerInViewMode = false;

            // Pause the animations where they are so that we can resume them later
            recordPlayerAnimMixer.update(0.1);

            recordPlayerSong.pause();
        };

        focusOnObject(recordPlayer, {
            x: 1.8,
            y: 0.2,
            z: -1.72
        }, () => {
            recordPlayerInViewMode = true;

            recordPlayerAnimations.forEach((clip) => {
                recordPlayerAnimMixer.clipAction(clip).play();
            });

            if (!recordPlayerAnimStarted) {
                recordPlayerAnimStarted = true;

                setTimeout(() => {
                    recordPlayerSong.play();
                }, 5000);
            } else {
            // Continue playing the song
                recordPlayerSong.play();

                // Start the animations again from where they left off
                recordPlayerAnimMixer.update(0.1);
            }
        }, {
            lightObj: recordPlayerLight,
            intensity: 8
        });
    };

    const focusSodaCans = () => {
        // Update the discoverables
        updateDiscoverables("soda");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {};

        // By default, we just zoom in on the focus object. This centers the object on the screen.
        // This is great for some things like the Computer and TV, but in this case, we need to move
        // the camera to the left a bit so that the soda cans are centered on the screen.
        // To do this, we'll override the focus position.
        // make a copy of the focusObj
        const sodaCansCopy = sodaCans.clone();

        sodaCansCopy.position.x = sodaCans.position.x + 0.35;

        focusOnObject(
            sodaCansCopy,
            // {x: 0.3, y: -0.6, z: 0.73},
            { x: 0.5, y: -0.69, z: 0.9 },
            () => {
            // First make sure we scroll to the top of the scrollable text section
                sodaCansInfo.querySelector(".inner_wrapper").scrollTop = 0;

                // Show the soda cans info overlay
                sodaCansInfo.classList.remove("hidden");
            },
            {
                lightObj: sodaCansLight,
                intensity: 4
            }
        );
    };

    const focusComputer = () => {
        // Update the discoverables
        updateDiscoverables("computer");

        // Boot up the computer if it hasn't been started yet
        if (!computerStarted) {
            const diluxIFrame = document.getElementById("dilux_iframe");
            const startBtn = diluxIFrame.contentWindow.document.getElementById("start-button");
            startBtn.click();

            computerStarted = true;
        }

        // Set the callback for what to do when the camera later stops focusing on the computer
        resetCameraCallback = () => {
            // Allow constant rendering (painting) again
            canRender = true;

            const screensaver = scene.getObjectByName("screensaver");

            // Add the screensaver plane
            if (!screensaver) createScreensaver();

            // Hide the computer screen
            computerScreen.style.visibility = "hidden";
        };

        focusOnObject(computerScreenObj, { x: 0.77073, y: -0.18203, z: -1.55 }, () => {
            computerScreen.style.visibility = "visible";
            resizeComputerScreen();

            // Don't allow constant rendering (painting) while focusing on the computer
            // however we need to allow an extra moment for things to settle into place
            // before we stop rendering
            setTimeout(() => {
                canRender = false;
            }, 100);
        });
    };

    const focusRobot = () => {
        // Update the discoverables
        updateDiscoverables("robot");

        // When we exit out of view mode, udpate the view mode variable
        // and reset the robot.
        resetCameraCallback = () => {
        // Stop all the robot animations
            robotAnimMixer.stopAllAction();

            robotInViewMode = false;

            // Remove the overlay
            robotOverlay.classList.add("hidden");

            // Move the robot out of view again and hide it
            robotAnimated.position.x += 100;
            robotAnimated.visible = false;

            // Show the original robot object
            robotObj.visible = true;
        };

        focusOnObject(robotObj, {
            x: -1.57,
            y: -0.11,
            z: -1.9
        }, () => {
        // Set the appropriate flags that the robot is in view so that we can click on it to play the animation
            robotInViewMode = true;
            canHover = true;
            canRaycast = true;

            // Show the overlay
            robotOverlay.classList.remove("hidden");
        }, {
            lightObj: robotLight,
            intensity: 1
        });
    };

    const focusClock = () => {
    // Update the discoverables
        updateDiscoverables("clock");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {
            clockInViewMode = false;

            // Remove the clock choices
            clockFacesWrapper.classList.add("hidden");
        };

        focusOnObject(clockRim, {
            x: -1.9,
            y: 0.25555,
            z: -1.653
        }, () => {
            clockInViewMode = true;

            // Show the clock face choices
            clockFacesWrapper.classList.remove("hidden");
        }, {
            lightObj: clockLight,
            intensity: 8
        });
    };

    const focusCorkboard = () => {
    // Update the discoverables
        updateDiscoverables("corkboard");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {
            corkboardInViewMode = false;

            // Hide the corkboard overlay if visible
            corkboardOverlay.classList.add("hidden");
        };

        focusOnObject(
            corkboardObj, {
                x: -2.1,
                y: 0,
                z: 0.3
            }, () => {
            // Set the appropriate flags when the cork board is in view so that we can click on them to view the documents
                corkboardInViewMode = true;
                canHover = true;
                canRaycast = true;
            }, {
                lightObj: corkboardLight,
                intensity: 5
            }
        );
    };

    const focusBlanket = () => {
        // Update the discoverables
        updateDiscoverables("bed");

        // Nothing to do here when zooming back out
        resetCameraCallback = () => {
            blanketInViewMode = false;

            blanketOverlay.classList.add("hidden");

            // If the bed was made, we don't want to allow the user to interact with it again so we'll move the messy blanket out of the way
            if (bedMade) {
            // We'll add a 2 second delay so that this doesn't happen until the camera has reset to the center of the room.
            // Otherwise the camera will jump around.
                setTimeout(() => {
                // Move the messy blanket out of view and hide it
                    blanketObj.position.x += 100;
                    blanketObj.visible = false;
                }, 2000);
            }
        };

        focusOnObject(blanketObj, {
            x: -1.9,
            y: 0.4,
            z: -0.3
        }, () => {
        // Set the appropriate flags that the blanket is in view so that we can click on it to make the bed
            blanketInViewMode = true;
            canHover = true;
            canRaycast = true;

            blanketOverlay.classList.remove("hidden");
        }, {
            lightObj: blanketLight,
            intensity: 30
        });
    };

    const focusTV = () => {
        // Update the discoverables
        updateDiscoverables("tv");

        focusOnObject(hiddenTVScreenObj, { x: 1.6025, y: -0.25596, z: 1.62423 }, () => {
            tvScreen.style.visibility = "visible";

            // Show the game iframe
            addGameIframe();
        }, {
            lightObj: tvLight,
            intensity: 1
        });

        resetCameraCallback = () => {
        // Remove the game iframe
            pauseGameIframe();

            gamePaused = true;

            // Be sure to hide the controls overlay in case it's still open
            controlsWrapper.classList.add("hidden");

            // Allow constant rendering (painting) again
            canRender = true;
        };
    };


    // ================================================================================================================================
    /**
     * E V E N T   L I S T E N E R S
     */
    // ================================================================================================================================

    window.addEventListener("mousedown", onMouseDown, false);
    window.addEventListener("mousemove", onPointerMove, false);
    window.addEventListener("wheel", onMouseWheel, false);

    // Min and max Y values for limiting the camera position when using the orbital controls
    const orbitMinY = -1;
    const orbitMaxY = 0.8;

    // When using the orbital controls to pan around, make sure that the camera doesn't go through
    // the floor or the ceiling.
    controls.addEventListener("change", function() {
    // Don't allow movement if in view mode
        if (isFocusingOnObject) return;

        // Check and limit the camera position along the Y-axis
        if (camera.position.y < orbitMinY) {
            camera.position.y = orbitMinY;
        } else if (camera.position.y > orbitMaxY) {
            camera.position.y = orbitMaxY;
        }
    });

    // When clicking on the contact item in the menu... toggle the contact form
    document.querySelector(".menu_wrapper .contact").addEventListener("click", toggleContactForm);

    // Check for when the game has started. The game iframe will send a message to this window when it starts.
    window.addEventListener("message", function(event) {
    // Check the origin of the message for security
        if (event.origin !== window.location.origin) {
            return;
        }

        if (event.data === "GAME_STARTED") {
            gameStarted = true;
        }
    });

    // ! ONLY USE FOR DEVELOPMENT
    // document.addEventListener("keydown", (e) => {
    //     // escape key
    //     // Go back to the center of the scene and look at the original location
    //     if (e.keyCode === 27) {
    //         exitFocus();
    //     }
    // });

    // When the back button is clicked, exit the focus
    goBackBtn.addEventListener("click", () => {
        exitFocus();
    });

    // Menu click
    document.querySelector(".menu_buttons_wrapper").addEventListener("click", (e) => {
        // If it's active, we want to make sure that we can't interact with the scene
        if (!document.querySelector(".menu_buttons_wrapper").classList.contains("active")) {
            menuOpen = true;

            // We don't want to be able to raycast while the menu is open
            canRaycast = false;

            // We don't want to be able to interact with the controls either otherwise when we close the menu,
            // the controls will jerk around if we triggered their movement.
            controls.enabled = false;
        } else {
            menuOpen = false;
            canRaycast = true;
            controls.enabled = true;
        }

        toggleMenu(e);
    });

    // When hovering over the star icon, show the tooltip
    starIcon.addEventListener("mouseover", () => {
    // If the UI bar is not showing, then the star icon is in the corner. 
    // In this case we need to add a class to the tooltip so that it shows up in the corner.
        if (!uiWrapper.classList.contains("show")) {
            starTooltip.classList.add("corner");
        } else {
        // If the corner class is already added, remove it
            starTooltip.classList.remove("corner");
        }

        starTooltip.classList.add("show");
    });

    // When the user no longer hovers over the star icon button, hide the tooltip
    starIcon.addEventListener("mouseleave", () => {
        starTooltip.classList.remove("show");
    });

    const introWrapperClickEvent = () => {
        // Show the menu
        document.querySelector(".menu_buttons_wrapper").style.display = "block";

        const introWrapperMask = document.querySelector(".intro_wrapper_invisible_mask");
        document.body.removeChild(introWrapperMask);

        // Remove the grayscale filter from the canvas
        canvas.classList.remove("grayscale");

        // Allow raycasting to work again
        canRaycast = true;

        // Remove the event listener
        introWrapperClose.removeEventListener("click", introWrapperClickEvent);
    };

    // When the 3D scene loads, we show the intro wrapper. When the user clicks on the X, remove the wrapper
    introWrapperClose.addEventListener("click", introWrapperClickEvent);

    /**
     * C O N T R O L S  O V E R L A Y  L O G I C
     */
    // When the user clicks on the close button, hide the controls overlay
    controlsWrapperClose.addEventListener("click", () => {
    // Don't show the controls overlay again when the user navigtes to focus on the TV
        controlsVisible = false;

        const controlsWrapperMask = document.querySelector(".controls_wrapper_invisible_mask");
        controlsWrapperMask.classList.add("hidden");

        // Make sure to give the game iframe focus again
        const iframe = document.querySelector(".tv_screen iframe");
        iframe.contentWindow.focus();

        // Add the controller icon
        addControllerIcon();
    });

    // Whenever the user hovers over the direction controller buttons...
    controllerDirBtn.addEventListener("mouseover", () => {
        controlKeyAction.innerHTML = "LEFT / UP / RIGHT / DOWN";

        // Make the "backlight" shape white
        keyboardDirBacklight.style.fill = "#fff";

        // Set the keyboard direction buttons to be orange
        keyboardDirBtnEls.forEach((el) => {
            el.classList.add("active");
        });
    });

    // Whenever the user stops hovering over the direction controller buttons...
    controllerDirBtn.addEventListener("mouseleave", () => {
        resetKeyAction();

        // Set the keyboard direction buttons to be none
        keyboardDirBacklight.style.fill = "";

        // Set all the keyboard direction buttons to be white
        keyboardDirBtnEls.forEach((el) => {
            el.classList.remove("active");
        });
    });

    // Whenever the user hovers over the start button...
    controllerStartBtn.addEventListener("mouseover", () => {
        controlKeyAction.innerHTML = "START / PAUSE";

        // Make the "backlight" shape white
        keyboardEnterBacklight.style.fill = "#fff";

        // Set the keyboard start button to be orange
        keyboardEnterBtnEls.forEach((el) => {
            el.classList.add("active");
        });
    });

    // Whenever the user stops hovering over the start button...
    controllerStartBtn.addEventListener("mouseleave", () => {
        resetKeyAction();

        // Make the "backlight" shape have no fill
        keyboardEnterBacklight.style.fill = "";

        // Set the keyboard start button to be white
        keyboardEnterBtnEls.forEach((el) => {
            el.classList.remove("active");
        });
    });

    // Whenever the user hovers over the A button...
    controllerABtn.addEventListener("mouseover", () => {
        controlKeyAction.innerHTML = "THROW";

        // Make the "backlight" shape white
        keyboardShiftBacklight.style.fill = "#fff";

        // Set the keyboard A button to be orange
        keyboardShiftBtnEls.forEach((el) => {
            el.classList.add("active");
        });
    });

    // Whenever the user stops hovering over the A button...
    controllerABtn.addEventListener("mouseleave", () => {
        resetKeyAction();

        // Make the "backlight" shape have no fill
        keyboardShiftBacklight.style.fill = "";

        // Set the keyboard A button to be white
        keyboardShiftBtnEls.forEach((el) => {
            el.classList.remove("active");
        });
    });

    // Whenever the user hovers over the B button...
    controllerBBtn.addEventListener("mouseover", () => {
        controlKeyAction.innerHTML = "JUMP";

        // Set the keyboard B button to be orange
        keyboardSpaceBtn.classList.add("active");
    });

    // Whenever the user stops hovering over the B button...
    controllerBBtn.addEventListener("mouseleave", () => {
        resetKeyAction();

        // Set the keyboard B button to be white
        keyboardSpaceBtn.classList.remove("active");
    });

    // Whenever the user clicks on the close button within the success overlay, hide the overlay
    successOverlayClose.addEventListener("click", () => {
        successOverlay.classList.add("hidden");
    });

    // Hover over the info button
    infoBtn.addEventListener("mouseover", () => {
        infoOverlay.classList.add("show");

        // Update the info overlay text based on the focus object
        switch (selectedObject) {
            case synthObj:
                infoOverlayTxt.innerHTML = `
            <span>Designed with:</span>
            <ul>
                <li>- Figma</li>
                <li>- Blender</li>
            </ul>
        `;
                break;
            case guitarCase:
                infoOverlayTxt.innerHTML = `
                <span>Designed with:</span>
                <ul>
                    <li>- Figma (raining SVG musical instruments)</li>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case trash:
                infoOverlayTxt.innerHTML = `
                <span>Designed with:</span>
                <ul>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case recordPlayer:
                infoOverlayTxt.innerHTML = `
                <span>Designed with:</span>
                <ul>
                    <li>- Figma</li>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
                <br />
                <span>Song credits:</span>
                <ul>
                    <li>- Written and performed by myself (Dylan Hepworth).</li>
                    <li>- Recorded and mastered with Logic Pro X.</li>
                </ul>
            `;
                break;
            case robotObj:
                infoOverlayTxt.innerHTML = `
                <p>Inspired by the Robie Robot piggie bank from the late great RadioShack.</p>
                <span>Designed with:</span>
                <ul>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case clockRim:
                infoOverlayTxt.innerHTML = `
                <p>The default clock was inspired by my dad’s Swatch watch that he wore almost every day.
                </p>
                <span>Designed with:</span>
                <ul>
                    <li>- Figma</li>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case corkboardObj:
                infoOverlayTxt.innerHTML = `
                <span>Designed with:</span>
                <ul>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case blanketObj:
                infoOverlayTxt.innerHTML = `
                <span>Designed with:</span>
                <ul>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case computerScreenObj:
                infoOverlayTxt.innerHTML = `
                <p>Inspired by my love of 80s micro computers. Intended as an amalgamation between the Lisa, Macintosh, Tandy 1000 and Xerox 8010.</p>
                <span>Designed with:</span>
                <ul>
                    <li>- Figma</li>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
            `;
                break;
            case hiddenTVScreenObj:
                infoOverlayTxt.innerHTML = `
                <p>I developed this game drawing inspiration from Nintendo's iconic "Super Mario Bros 1, 2, and 3," along with Konami's "Little Nemo: The Dream Master". The entire project is powered by my own bespoke game engine, meticulously crafted in JavaScript and utilizing HTML canvas. No additional libraries or frameworks were used in the making of this game.</p>
                <span>Designed with:</span>
                <ul>
                    <li>- Asperite</li>
                    <li>- Figma</li>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
                <br />
                <span>Sound design:</span>
                <ul>
                    <li>- FL Studio</li>
                    <li>- Logic Pro X</li>
                    <li>- Magical 8bit Plug VST</li>
                </ul>
            `;
                break;
            default:
                infoOverlayTxt.innerHTML = `
                <span>Designed with:</span>
                <ul>
                    <li>- Figma</li>
                    <li>- Photoshop</li>
                    <li>- Blender</li>
                </ul>
            `;
                break;
        }
    });

    infoBtn.addEventListener("mouseleave", () => {
        infoOverlay.classList.remove("show");
    });

    // When the window is resized, update the camera aspect ratio so that the scene remains in view and in full
    window.addEventListener("resize", () => {
        checkScreenSize();

        // Update the camera aspect ratio
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);

        // If we're viewing the computer screen or the tv screen, then the render loop
        // isn't calling for the screen to actually render (because we're trying to save on resources)
        // so we need to manually render it here so that things are repainted.
        if (!canRender) {
            renderer.render(scene, camera);
        }

        // Update the computer screen width
        resizeComputerScreen();
        resizeTVScreen();
    }, false);

    // When clicking on the "Download Resume" button, download the resume
    window.addEventListener("click", (e) => {
    // If the target is the resume button...
        if (e.target === resumeBtn) {
            window.open("../assets/docs/dylanHepworthResume24.pdf");
        }
    });

    // When clicking on the papers overlay X, hide the overlay
    corkboardOverlayClose.addEventListener("click", () => {
        corkboardOverlay.classList.add("hidden");
    });

    // When clicking on the first clock face choice, update the clock face
    clockFace1.addEventListener("click", () => {
        updateClockFace(1);
    });

    // When clicking on the second clock face choice, update the clock face
    clockFace2.addEventListener("click", () => {
        updateClockFace(2);
    });

    // When clicking on the third clock face choice, update the clock face
    clockFace3.addEventListener("click", () => {
        updateClockFace(3);
    });

    // ================================================================================================================================
    /**
     * E V E N T  L O O P
     */
    // ================================================================================================================================
    let totalPaints = 0;

    // Preload El Soñador's TV screen images
    const tvPausedImg = textureLoader.load("../assets/images/sonadorscreen/paused.jpg", (texture) => {
        return texture;
    });

    const tvNotStartedIMg = textureLoader.load("../assets/images/sonadorscreen/1.jpg", (texture) => {
        return texture;
    });

    // Render loop. Called on every frame.
    const render = (now) => {
        const t = now;

        const delta = clockTimer.getDelta();

        if (robotInViewMode) {
        // If the robot animation mixer exists, update it
            if (robotAnimMixer) robotAnimMixer.update(delta);
        }

        if (recordPlayerInViewMode) {
        // If the record player animation mixer exists, update it
            if (recordPlayerAnimMixer) recordPlayerAnimMixer.update(delta);
        }

        // TODO! Remove this before going live
        // Update the stats
        // stats.update();

        // Find the object in the scene with the name "screensaver";
        const screensaver = scene.getObjectByName("screensaver");

        const tvScreenSaver = scene.getObjectByName("tvScreensaver");

        // Every half second, update the tv screen saver
        if (tvScreenSaver) {
            if (gameStarted && gamePaused) {
                tvScreenSaver.material.map = tvPausedImg;
            } else {
                tvScreenSaver.material.map = tvNotStartedIMg;
            }
        }

        // If it's raining down instruments, update Tween
        if (isRainingInstruments) {
            TWEEN.update(t);
        }

        /**
         * CLOCK TICKING LOGIC
         */
        // Only render update the clock hands if we're not focusing on the computer or tv
        if (
            ((canRender ||
        isFocusingOnObject ||
        robotInViewMode ||
        synthInViewMode ||
        recordPlayerInViewMode ||
        clockInViewMode) &&
        menuOpen === false) ||
        loadedFirstFrame === false
        ) {
            // There are 89 painting loops to render all the objects in the scene.
            // Once they've all been painted, we no longer need to render the scene constantly.
            if (totalPaints < 90) {
                totalPaints++;
            } else {
                loadedFirstFrame = true;
            }

            now *= 0.001; // convert to seconds

            const date = new Date();
            const hour = date.getHours();
            const minute = date.getMinutes();

            if (clockHour?.rotation) {
                // Rotate the clock hands to the current time
                clockHour.rotation.z = (hour * 30) * -(Math.PI / 180);
                clockMinute.rotation.z = (minute * 6) * -(Math.PI / 180);

                if (clockInViewMode) {
                    const second = date.getSeconds();

                    // Rotate the second hand in a sweeping motion rather than ticking
                    const nowDate = new Date();
                    const secondRotationAngle = (second * 360 / 60) + (nowDate.getMilliseconds() / 1000 * 360 / 60);
                    clockSecond.rotation.z = secondRotationAngle * -(Math.PI / 180);

                    // Show the clock second
                    clockSecond.visible = true;
                } else {
                // Hide the clock second
                    clockSecond.visible = false;
                }
            }

            // Raycaster
            raycaster.setFromCamera(pointer, camera);

            // Calculate objects intersecting the picking ray
            const intersects = raycaster.intersectObjects(scene.children, true);

            // List of all the mesh names that can trigger a hover effect
            const hoverObjArr = [
                "clockRim",
                "computer",
                "computer_1",
                "computer_2",
                "ComputerScreen",
                "clipboardPapers",
                "robot",
                "tv_1",
                "tv_2",
                "tv_3",
                "tvKnobs_2",
                "tvScreen",
                "synth_1",
                "synth_2",
                "corkBoard",
                "clipboardPapers",
                "clipboardPapers001",
                "clockGlass",
                "recordPlayerCover",
                "cassetteCover",
                "sodaCans_1",
                "recordPlayer",
                "recordPlayer_1",
                "recordPlayer_2",
                "recordPlayer_3",
                "recordPlayer004",
                "recordPlayerTurntable",
                "record_1",
                "sodaCans",
                "sodaCans_2",
                "screensaver",
                "tvScreensaver",
                "garbagePaper",
                "garbagePaper_1",
                "trashPaper",
                "guitarCase",
                "blanketMessy",
                "Cube012_1"
            ];

            if (intersects.length > 0 && canRaycast === true && !menuOpen) {
                if (intersected !== intersects[0].object) {
                    if (intersected && intersected.type !== "Reflector") {
                        if (intersected.currentHex === 0) {
                            intersected.material.emissive.setHex(intersected.currentHex);
                        }
                        resetAllHex();
                    }

                    intersected = intersects[0].object;

                    if (hoverObjArr.includes(intersected.name) && canHover) {
                        canvas.style.cursor = "pointer";

                        if (intersected === clockRim) {
                            clockGlass.material.emissive.setHex(0x7e7e7e);
                            clockGlass.material.emissiveIntensity = 0.5;
                        // clockGlass.material.emissive.setHex(0x0066FF);
                        } else if (intersected === clockGlass) {
                            clockRim.material.emissive.setHex(0x7e7e7e);
                            clockRim.material.emissiveIntensity = 0.5;
                        } else if (intersected === corkboardObj && !corkboardInViewMode) { // Only highlight the corkboard if NOT in view mode
                            papersObj1.material.emissive.setHex(0x7e7e7e);
                            papersObj1.material.emissiveIntensity = 0.5;

                            papersObj2.material.emissive.setHex(0x7e7e7e);
                            papersObj2.material.emissiveIntensity = 0.5;
                        } else if (intersected === papersObj1 || intersected === papersObj2) {
                            if (!corkboardInViewMode)  {
                                corkboardObj.material.emissive.setHex(0x7e7e7e);
                                corkboardObj.material.emissiveIntensity = 0.5;
                            }
                        } else if (intersected.parent.name === "tv") {
                            intersected.parent.children.forEach((child) => {
                                child.material.emissive.setHex(0x7e7e7e);
                                child.material.emissiveIntensity = 0.5;
                            });

                            tvScreenSaver.material.emissive.setHex(0x7e7e7e);
                            tvScreenSaver.material.emissiveIntensity = 0.5;
                        } else if (intersected.name === "tvScreensaver") {
                        // Find all the objects who's parent is the tv
                            const tvChildren = tvObj.children;

                            tvChildren.forEach((child) => {
                                child.material.emissive.setHex(0x7e7e7e);
                                child.material.emissiveIntensity = 0.5;
                            });

                            tvScreenSaver.material.emissive.setHex(0x7e7e7e);
                            tvScreenSaver.material.emissiveIntensity = 0.5;
                        } else if (intersected === screensaver) {
                        // Find all the objects who's parent is the computer
                            const computerChildren = computerObj.children;

                            computerChildren.forEach((child) => {
                                child.material.emissive.setHex(0x7e7e7e);
                                child.material.emissiveIntensity = 0.5;
                            });
                        } else if (intersected.parent.name === "recordPlayer") {
                        // Apply the hover affect to the record player cover
                            recordPlayerCoverObj.material.emissive.setHex(0x7e7e7e);
                            recordPlayerCoverObj.material.emissiveIntensity = 0.5;

                            // Apply the hover affect to the cassette cover
                            cassetteCoverObj.material.emissive.setHex(0x7e7e7e);
                            cassetteCoverObj.material.emissiveIntensity = 0.5;

                            intersected.parent.children.forEach((child) => {
                                child.material.emissive.setHex(0x7e7e7e);
                                child.material.emissiveIntensity = 0.5;
                            });
                        } else if (intersected === cassetteCoverObj || intersected === recordPlayerCoverObj) {
                        // Apply the hover affect to the record player
                            recordPlayer.children.forEach((child) => {
                                child.material.emissive.setHex(0x7e7e7e);
                                child.material.emissiveIntensity = 0.5;
                            });

                            recordPlayerCoverObj.material.emissive.setHex(0x7e7e7e);
                            recordPlayerCoverObj.material.emissiveIntensity = 0.5;

                            cassetteCoverObj.material.emissive.setHex(0x7e7e7e);
                            cassetteCoverObj.material.emissiveIntensity = 0.5;
                        } else if (
                            intersected.parent.name === "Computer" ||
                        intersected.parent.name === "synth" ||
                        intersected.parent.name === "guitarCase" ||
                        intersected.parent.name === "sodaCans" ||
                        intersected.parent.name === "trash"
                        ) {
                            intersected.parent.children.forEach((child) => {
                                child.material.emissive.setHex(0x7e7e7e);
                                child.material.emissiveIntensity = 0.5;
                            });
                        }

                        if (!corkboardInViewMode) {
                            if (intersected.material.emissive) {
                                intersected.currentHex = intersected.material.emissive.getHex();
                                intersected.material.emissive.setHex(0x7e7e7e);
                                intersected.material.emissiveIntensity = 0.5;
                            }
                        } else {
                        // Specifically if we're in view mode of the cork board, we don't want to allow highlighting of the cork board itself but rather just the papers
                            if (intersected.name !== "corkBoard") {
                                intersected.currentHex = intersected.material.emissive.getHex();
                                intersected.material.emissive.setHex(0x7e7e7e);
                                intersected.material.emissiveIntensity = 0.5;
                            }
                        }
                    } else {
                        canvas.style.cursor = "default";
                    }
                }
            } else {
                if (intersected && intersected.type !== "Reflector") {
                    intersected.material.emissive.setHex(intersected.currentHex);
                    intersected.material.emissiveIntensity = 0.5;
                    resetAllHex();
                }

                intersected = null;
            }

            if (selectedObject?.position) {
                camera.target = new THREE.Vector3(selectedObject.position.x, selectedObject.position.y, selectedObject.position.z);
                camera.lookAt(camera.target);
            }

            TWEEN.update(t);

            renderer.render(scene, camera);
        }

        canRender = false;
    };

    // Reusable function that sets the key action text to blank
    const resetKeyAction = () => {
        controlKeyAction.innerHTML = "&nbsp;";
    };

    // Animation loop
    const animate = () => {
        requestAnimationFrame(animate);
        render();
    };

    // When this is first invoked, add the computer screen and TV screen
    addComputerScreen();
    addTVScreen();

    setTimeout(() => {
        // Add a new plane geometry that overlays the tv screen
        const planeGeometry = new THREE.PlaneGeometry(0.378, 0.2835);

        // Add a background image to the plane
        textureLoader.load("../assets/images/sonadorscreen/1.jpg", (texture) => {
            const material = new THREE.MeshPhongMaterial({
                color: 0xffffff,
                map: texture,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 1,
                depthWrite: false
            });

            const plane = new THREE.Mesh(planeGeometry, material);

            // Set the name of the plane
            plane.name = "tvScreensaver";

            // Set the position of the plane
            plane.position.x = 1.95933;
            plane.position.y = -0.25596;
            plane.position.z = 1.62423;

            // rotate the plane 90 degrees
            plane.rotateY(Math.PI / -2);

            // Set the original emissive hex for resetting after hovering
            plane.originalHex = plane.material.emissive.getHex();

            // Add the plane to the scene
            scene.add(plane);
        });
    }, 2000);
};

const toggleMenu = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    const menuButton = document.querySelector(".menu_buttons_wrapper");
    const menuWrapper = document.querySelector(".menu_wrapper");

    // Are we on the intial view?
    const isInitialView = document.body.classList.contains("initial_view");

    // Is the menu open?
    const isMenuOpen = menuButton.classList.contains("active");

    // If the menu isn't open (meaning we're opening it), and we're on the initial view,
    // then we need to invert the colors of the menu button and logo wrapper
    if (!isMenuOpen && isInitialView) {
        document.querySelector(".logo_wrapper").style.filter = "invert(0)";
        menuButton.style.filter = "invert(0)";
    } else if (isMenuOpen && isInitialView) {
        document.querySelector(".logo_wrapper").style.filter = "invert(1)";
        menuButton.style.filter = "invert(1)";
    } else if (!isMenuOpen && !isInitialView) {
        // This isn't the initial view, so remove the "initial" class so that there is no background
        menuWrapper.classList.remove("initial");

        // Now add the grayscale class to the webgl canvas
        document.getElementById("webgl").classList.add("grayscale");

        document.querySelector(".star_btn").style.visibility = "hidden";
    } else if (isMenuOpen && !isInitialView) {
        // This isn't the initial view, so remove the grayscale class from the webgl canvas
        document.getElementById("webgl").classList.remove("grayscale");

        document.querySelector(".star_btn").style.visibility = "visible";
    }

    menuButton.classList.toggle("active");

    // If the menu is open, and the user is closing it, then we need to make sure that the contact form is no longer showing
    if (isMenuOpen) {
        const contactForm = document.querySelector(".contact_form_wrapper");

        // If the contact form is already hidden, don't do anything
        if (!contactForm.classList.contains("hidden")) {
            contactForm.classList.add("hidden");

            // Show the other menu items
            document.querySelectorAll(".menu_wrapper > ul > li").forEach((el) => {
                el.classList.remove("hidden");
            });

            // Remove the active class to the menu
            document.querySelector(".menu_wrapper > ul").classList.remove("active");
        }
    }

    menuWrapper.classList.toggle("show");
};

const toggleContactForm = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    const contactForm = document.querySelector(".contact_form_wrapper");

    // If the contact form is already showing, don't do anything
    if (!contactForm.classList.contains("hidden")) {
        return;
    } else {
        contactForm.classList.remove("hidden");

        // Hide the other menu items
        document.querySelectorAll(".menu_wrapper > ul > li").forEach((el) => {
            el.classList.add("hidden");
        });

        // Add the active class to the menu
        document.querySelector(".menu_wrapper > ul").classList.add("active");
    }
};
