import { loadScene } from "./scene.js";

/**
 * I N I T I A L  S C R E E N  L O G I C
 */
const initialScreenWrapper = document.querySelector(".initial_content_wrapper");
const initialDialog = document.querySelector(".initial_dialog");
const initialDialogHeader = document.querySelector(".initial_dialog h1");
const initialDialogText = document.querySelector(".initial_dialog p");
const initialDialogBtn = document.querySelector(".initial_dialog .initial_btn_wrapper > button");
const progressBarWrapper = document.querySelector(".progress_bar_container");
const progressBar = document.querySelector("#progress_bar");
const circleClip = document.querySelector(".circle_clip");
const menuButton = document.querySelector(".menu_buttons_wrapper");
let bounds;

menuButton.style.display = "none";

const moveInitialDialog = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;

    const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2
    };
    const distance = Math.sqrt(center.x**2 + center.y**2);

    initialDialog.style.transform = `
        scale3d(1.07, 1.07, 1.07)
        rotate3d(
            ${center.y / 2},
            ${-center.x / 2},
            0,
            ${Math.log(distance)* 2}deg
        )`;

    initialDialog.style.boxShadow = `
        ${(center.x / 30) + 10}px
        ${(center.y / 30) + 10}px
        0
        0px
        #000`;


    initialDialog.querySelector(".initial_glow_overlay").style.backgroundImage = `
        radial-gradient(
            circle at
            ${center.x * 0.5 + bounds.width/2}px
            ${center.y * 0.5 + bounds.height/2}px,
            #ffffff55,
            #00000021
        )`;
};

// Make sure the screensize is large enough to display the portfolio (greater than 1000 pixels).
export const checkScreenSize = () => {
    const existingMobileTextDiv = document.querySelector(".mobile_text");

    // Check to make sure that the screen is wide enough to properly display the portfolio
    if (window.innerWidth < 1000) {
        // If the mobile text div already exists, don't do anything
        if (existingMobileTextDiv) return;

        // Hide the initial dialog
        initialDialog.style.visibility = "hidden";
        initialDialog.style.display = "none";

        // Add a new div to the DOM that will hold the text
        const mobileText = document.createElement("div");

        // Add the mobile text class
        mobileText.classList.add("mobile_text");

        // Add the text to the div
        mobileText.innerHTML = "<p>Due to the interactive nature of this portfolio, it is not optimized for mobile devices and is is best viewed on a desktop or laptop.</p><br />";
        mobileText.innerHTML += "<p>If you would like to see some examples of my mobile work, please visit my <a href='https://www.helloimdylan.com/projects.html' target='_blank'>old portfolio</a>.</p><br />";
        mobileText.innerHTML += "<p>You can also view a full preview of this portfolio on <a href='https://youtu.be/u-LLfaI5RjA' target='_blank'>YouTube</a>.</p>";

        // Add the mobile text to the DOM
        initialScreenWrapper.appendChild(mobileText);

        return;
    } else {
        if (existingMobileTextDiv) {
            existingMobileTextDiv.remove();
            initialDialog.style.display = "block";
            initialDialog.style.visibility = "visible";
        }
    }
};

// When the page loads, check the screen size and setup bounds for the initial dialog. Add a mousemove event listener to the document
// so that the initial dialog will move when the mouse moves.
const domLoadedEvents = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    // Display personal info that will be shown in the console
    const logo = "000000000000000000000000000000000000000\n000000000000000000000000000000000000000\n000000000000000000000000000000000000000\n0000#,.,*/(#0000000000#(.........,#0000\n0000# .###/. ,#0000000#/ #00000#. #0000\n0000# .#0000/,.#000000#/ #00000#. #0000\n0000# .#00000#,.(00000#/ #00000#. #0000\n0000# .#000000/ ,00000#/ #00000#. #0000\n0000#  ,,,,,,,,  ,,,,,,. #00000#. #0000\n0000000000000000000000000000000#. #0000\n0000000000000000000000000000000#. #0000\n0000#((((((((((((((((((((000000#. #0000\n0000#  ,,,,,,,,  ,,,,,,. #00000#. #0000\n0000# .#000000/ ,00000#/ #00000#. #0000\n0000# .#00000#.,(00000#/ #00000#. #0000\n0000# .#0000(,.#000000#/ #00000#. #0000\n0000# .((/, ,/#0000000#/ (#####(. #0000\n0000#,..,*/#0000000000#(.........,#0000\n000000000000000000000000000000000000000\n0000#(((((((((((((((((((((((((((((#0000\n0000##############################00000\n000000000000000000000000000000000000000";
    console.log("%c" + logo, "color: #F38A1B; font-weight: bold");
    console.log("%cThank you for visiting my portfolio.", "color: #216692; font-weight: bold; font-size: 14px");
    const personalDetails = {
        "Favorite Bands": "Coheed & Cambria, Pink Floyd, Led Zeppelin, Rush",
        "Hobbies": "Playing music, 3D modeling, Video games, Travelling, Watching movies, Spending time with my wife and kids",
        "Number of Children": 2,
        "Vice": "C8H10N4O2",
        "Loves Cats": true,
        "Loves Dogs": true
    };
    console.table([personalDetails]);

    checkScreenSize();

    bounds = initialDialog.getBoundingClientRect();
    document.addEventListener("mousemove", moveInitialDialog);

    document.removeEventListener("DOMContentLoaded", domLoadedEvents);
};

document.addEventListener("DOMContentLoaded", domLoadedEvents);

window.addEventListener("resize", checkScreenSize);

let hasClicked = false;

// When clicking on the "Time Travel" button, start the time travel effect and start loading the scene
initialDialogBtn.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    if (hasClicked) return;
    hasClicked = true;

    // Remove the button
    initialDialogBtn.remove();

    // Get the width of the dialog
    const dialogWidth = initialDialog.getBoundingClientRect().width;

    // get the height of the dialog
    const dialogHeight = initialDialog.getBoundingClientRect().height;

    // Hide the dialog
    initialDialog.style.visibility = "hidden";

    const minimizeEffect = () => {
        const reverseFrames = () => {
            for (let i = 10; i > 0; i--) {
                // Draw a new div on the screen that will be the height and width of the dialog
                const frameBorder = document.createElement("div");

                // Add the frame border class
                frameBorder.classList.add("frame_border");

                // Set the width and height of the frame border
                frameBorder.style.width = (dialogWidth - (i * 40)) + "px";
                frameBorder.style.height = (dialogHeight - (i * 40)) + "px";

                frameBorder.style.top = `calc(50% + ${(i * 80)}px`;
                frameBorder.style.left = `calc(50% - ${(i * 120)}px`;

                // Delay adding the frame border to the DOM
                setTimeout(() => {
                    // Add the frame border to the DOM
                    initialScreenWrapper.appendChild(frameBorder);

                    setTimeout(() => {
                        // Remove the frame border from the DOM
                        frameBorder.remove();

                        // Is this the last frame border?
                        if (i === 1) {
                            showLoaderDialog();
                        }
                    }, 150);
                }, (10 - i) * 50);
            }
        };

        // Add the frame borders that will act as though the shrink and move to the lower left corner of the screen
        for (let i = 0; i < 10; i++) {
            // Draw a new div on the screen that will be the height and width of the dialog
            const frameBorder = document.createElement("div");

            // Add the frame border class
            frameBorder.classList.add("frame_border");

            // Set the width and height of the frame border
            frameBorder.style.width = (dialogWidth - (i * 40)) + "px";
            frameBorder.style.height = (dialogHeight - (i * 40)) + "px";

            frameBorder.style.top = `calc(50% + ${(i * 80)}px`;
            frameBorder.style.left = `calc(50% - ${(i * 120)}px`;

            // Delay adding the frame border to the DOM
            setTimeout(() => {
                // Add the frame border to the DOM
                initialScreenWrapper.appendChild(frameBorder);

                setTimeout(() => {
                    // Remove the frame border from the DOM
                    frameBorder.remove();

                    // Is this the last frame border? If so, run the frames in reverse
                    if (i === 9) {
                        reverseFrames();
                    }
                }, 150);
            }, i * 50);
        }
    };

    const showLoaderDialog = () => {
        // Show the dialog
        initialDialog.style.visibility = "visible";

        // Update the header text
        initialDialogHeader.innerHTML = "<img src='../assets/images/clock.gif' /> Time Travel Activated...";

        // Add the centered class to the text
        initialDialogText.classList.add("centered");

        // Show the progress bar
        progressBarWrapper.classList.add("show");

        initialDialogText.innerHTML = "Flux capacitor engaged";

        // We no longer need to check for screen resizing
        window.removeEventListener("resize", checkScreenSize);

        // Start loading the 3D scene
        loadScene(initialDialog, initialDialogText, initialScreenWrapper, circleClip, progressBar);
    };

    minimizeEffect();
});
